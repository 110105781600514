export const linking = {
  config: {
    screens: {
      //marketing
      marketing: "",
      health: "/health",
      guestapp: "/guestapp",
      crewapp: "/crewapp",
      pbxsystem: "/pbxsystem",
      hospitalitypbx: "/hospitalitypbx",

      settings: "/settings",

      //dashboard
      dashboard: "/dashboard",

      //omni
      messagev2: "/messagev2",

      voicemail: "/voicemail",
      vvn: "/vvn",
      starlink: "/starlink",
      docs: "/docs",

      //auth
      auth: "/auth",
      authcode: "/authcode/:extension",

      //vvn
      pinmanagement: "/pinmanagement",
      pinhistory: "/pinhistory",
      callingrate: "/callingrate",
      reportschedule: "/reportschedule",

      //pbx
      extension: "/extension",
      inboundroute: "/inboundroute",
      gatewayreport: "/gatewayreport",
      appaccess: "/appaccess",
      links: "/links",
      featurecode: "/featurecode",
      gateway: "/gateway/:gateway",
      ringgroup: "/ringgroup",

      //gov
      gov: "/gov",

      upgrade: "/upgrade/:module",
      notfound: "*",
    },
  },
};

const routes = {
  //marketing
  marketing: "marketing",
  health: "health",
  guestapp: "guestapp",
  crewapp: "crewapp",
  pbxsystem: "pbxsystem",
  hospitalitypbx: "hospitalitypbx",

  settings: "settings",

  //dashboard
  dashboard: "dashboard",

  //omni
  messagev2: "messagev2",

  voicemail: "voicemail",
  vvn: "vvn",
  starlink: "starlink",
  docs: "docs",

  //auth
  auth: "auth",
  authcode: "authcode",

  //vvn
  pinmanagement: "pinmanagement",
  pinhistory: "pinhistory",
  callingrate: "callingrate",
  reportschedule: "reportschedule",

  //pbx
  extension: "extension",
  inboundroute: "inboundroute",
  gatewayreport: "gatewayreport",
  appaccess: "appaccess",
  links: "links",
  featurecode: "featurecode",
  gateway: "gateway",
  ringgroup: "ringgroup",

  //gov
  gov: "gov",

  upgrade: "upgrade",
  notfound: "notfound",
};

export default routes;
