import settings from "../../config/settings";

export const parseExtension = (extension) => {
  if (
    extension &&
    (extension?.startsWith(settings.accountPrefixApp) ||
      extension?.startsWith(settings.accountPrefixPhy))
  ) {
    return extension?.split("@")[0]?.substring(4);
  }

  return extension;
};

export const isPhysicalExtension = (description) => {
  return description?.match(/\d+,\d+,\d+,\d+/);
};
