import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "./NtText";
import NtDevider from "./NtDevider";
import colors from "../config/colors";
import NtListActionButton from "./NtListActionButton";

function NtListRow({
  item,
  columns,
  renderCustom,
  onActionLoading = false,
  onActionIcon,
  onActionColor,
  onActionPress,
  renderAction,
}) {
  const renderRow = (column) => {
    const render = renderCustom ? renderCustom(item, column?.id) : null;
    return (
      <View
        style={{ flex: 1, alignItems: "flex-start", justifyContent: "center" }}
        key={column.id}
      >
        <NtText style={styles.text}>{render ? render : item[column.id]}</NtText>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        {columns?.map((each) => renderRow(each))}
        {onActionPress && (
          <NtListActionButton
            color={onActionColor}
            icon={onActionIcon}
            loading={onActionLoading}
            onPress={onActionPress}
          />
        )}
        {renderAction && renderAction}
      </View>
      <NtDevider />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    fontSize: 13,
    fontWeight: "800",
    color: colors.lighGray,
  },
});

export default NtListRow;
