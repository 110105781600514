const featurecodecsvcolumndata = [
  {
    id: "code",
    title: "Code",
    default: true,
  },
  {
    id: "name",
    title: "Name",
    default: true,
  },
  {
    id: "details",
    title: "Details",
    default: true,
  },
];
export default featurecodecsvcolumndata;
