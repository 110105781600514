import Papa from "papaparse";
import Logger from "../../common/utils/Logger";
import settings from "../../config/settings";
import { createExtension } from "../../api/extension";
import { sleep } from "../../common/utils/ControllerUtils";

export const handleExtensionCreate = async (file) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        Logger("Done reading the .csv... will start processing now..");

        const success = [];
        const failure = [];

        results.data.map(async (each, index) => {
          const response = await createExtension(
            each.domain,
            each.extension,
            each.password,
            each.phone,
            each.name,
            each.description,
            each.title,
            each.location
          );

          //slowing down the request
          await sleep(500);

          if (response.ok) {
            success.push(each);
          } else {
            failure.push({
              extension: each,
              status: response.status,
              error: response.data,
            });
          }

          Logger("---- processing ", success.length, failure.length);

          if (success.length + failure.length == results.data.length) {
            resolve({
              ok: true,
              success: success,
              failure: failure,
              total: results.data.length,
            });
          }
        });
      },
    });
  });
};

export const handleFailureFile = async (failure) => {
  return new Promise((resolve, reject) => {
    resolve({ ok: true, data: JSON.stringify(failure, null, 2) });
  });
};
