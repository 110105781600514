const inboundroutecsvcolumndata = [
  {
    id: "dialplan_uuid",
    title: "ID",
  },
  {
    id: "dialplan_name",
    title: "Name",
    default: true,
  },
  {
    id: "dialplan_number",
    title: "Number",
    default: true,
  },
  {
    id: "dialplan_order",
    title: "Order",
  },
  {
    id: "dialplan_context",
    title: "Context",
    default: true,
  },
  {
    id: "dialplan_destination_actions",
    title: "Action",
    default: true,
  },
  {
    id: "dialplan_description",
    title: "Description",
    default: true,
  },
  {
    id: "dialplan_enabled",
    title: "Status",
  },
];
export default inboundroutecsvcolumndata;
