const cdrstatisticcolumndata = [
  {
    id: "type",
    title: "Type",
    default: true,
  },
  {
    id: "total_calls",
    title: "Total Calls",
    default: true,
  },
  {
    id: "current_calls",
    title: "Current Calls",
    default: true,
  },
  {
    id: "answered_calls",
    title: "Answered Calls",
    default: true,
  },
  {
    id: "free_minutes_given",
    title: "Free Minutes Given",
    default: true,
  },
  {
    id: "minutes_billed",
    title: "Minutes Billed",
    default: true,
  },
  {
    id: "total_cost",
    title: "Total Cost",
    default: true,
  },
  {
    id: "total_billed",
    title: "Total Billed",
    default: true,
  },
];
export default cdrstatisticcolumndata;
