const pbxstatisticcolumndata = [
  {
    id: "extensions",
    title: "Extensions",
    default: true,
  },
  {
    id: "ringgroups",
    title: "Ring Groups",
    default: true,
  },
  {
    id: "voicemails",
    title: "Voicemails",
    default: true,
  },
  {
    id: "cdrs",
    title: "CDRs",
    default: true,
  },
];
export default pbxstatisticcolumndata;
