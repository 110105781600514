import React, { useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtDevider from "../../components/NtDevider";
import colors from "../../config/colors";

function NtDashboardHeader({ containerStyle, headerContainerStyle, columns }) {
  const renderRow = (item) => {
    return (
      <View
        style={{ flex: 1, alignItems: "flex-start", justifyContent: "center" }}
        key={item.title}
      >
        <NtText style={styles.text}>{item.title}</NtText>
      </View>
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <View
        style={[
          {
            flexDirection: "row",
            alignItems: "center",
            padding: 6,
            backgroundColor: colors.blue,
          },
          headerContainerStyle,
        ]}
      >
        {columns?.map((each) => renderRow(each))}
      </View>
      <NtDevider />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    fontSize: 14,
    fontWeight: "700",
    color: "white",
  },
});

export default NtDashboardHeader;
