import React from "react";
import { View, StyleSheet } from "react-native";
import BaseExtensionController from "./BaseExtensionController";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import Logger from "../../common/utils/Logger";
import client from "../../api/client";
import { fetchExtensions } from "../../api/extension";
import NtText from "../../components/NtText";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import colors from "../../config/colors";
import environmentsdata from "../../root/data/environmentsdata";
import settings from "../../config/settings";
import NtListHeader from "../../components/NtListHeader";
import NtListRow from "../../components/NtListRow";
import NtListRecordsTitle from "../../components/NtListRecordsTitle";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtListEmpty from "../../components/NtListEmpty";

class GatewayReportController extends BaseExtensionController {
  state = {
    data: [],
    maxGateway: 0,
    loading: false,
    currentSite: null,
  };

  sites = [
    {
      id: "CPL",
      title: "CPL",
    },
    {
      id: "KPL",
      title: "KPL",
    },
    {
      id: "DPL",
      title: "DPL",
    },
    {
      id: "FPH",
      title: "FPH",
    },
    {
      id: "FAW",
      title: "FAW",
    },
    {
      id: "MPL",
      title: "MPL",
    },
    // {
    //   id: "CHT",
    //   title: "CHT",
    // },
    // {
    //   id: "SGW",
    //   title: "SGW",
    // },
  ];

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
    Logger("I am unmounting, will set the API back to normal");
    client.setBaseURL(this.context.user?.environment?.apiUrl);
  }

  handleFocus = () => {
    Logger("On focus change");
    this.handleAccessControl(settings.modulePbx);
  };

  handleDataFetch = async () => {
    this.setState({ loading: true });
    let allItems = [];
    for (let x = 0; x < this.sites.length; x++) {
      const environment = environmentsdata.find(
        (each) => each.code == this.sites[x].id
      );

      //will get the extensions for each site.....

      client.setBaseURL(environment?.apiUrl);
      // await sleep(1000);

      Logger("Fetching for domain ", environment.code);
      this.setState({ currentSite: environment });

      const response = await fetchExtensions(
        environment.domain,
        1,
        10000,
        settings.accountPrefixPhy,
        null,
        null,
        true
      );
      if (response.ok) {
        let data = this.filterExtensions(response.data?.extensions, "room");
        //will sort by gateway
        data = data.sort(
          (a, b) => a.description.split(",")[0] - b.description.split(",")[0]
        );

        if (response.ok) {
          allItems.push({
            code: environment?.code,
            data: data,
          });
        }
      }
    }

    // Will try to find the max number of gateways.....
    let maxGateway = 1;
    allItems.map((each) => {
      if (
        parseInt(each.data[each.data.length - 1]?.description?.split(",")[0]) >
        maxGateway
      ) {
        maxGateway = parseInt(
          each.data[each.data.length - 1]?.description?.split(",")[0]
        );
      }
    });

    Logger("Fetched data done", allItems, maxGateway);

    //finally will create the data.....
    const data = [...Array(maxGateway)].map((each, index) => {
      let row = {};
      let items = [{ id: "gateway", title: "Gateway" }, ...this.sites];
      for (let x = 0; x < items.length; x++) {
        let each = items[x];

        if (each.id == "gateway") {
          row.gateway = index + 1;
        } else {
          row[each.id] = this.fetchExtensionCount(each.id, index + 1, allItems);
        }
      }
      return row;
    });

    this.setState({
      data: data,
      maxGateway: maxGateway,
      loading: false,
      currentSite: null,
    });
  };

  handleExportFetch = async () => {
    Logger("Fetching Gataway Report data");

    return this.state.data;
  };

  handleExportDataParce = (item, column) => {
    return item[column];
  };

  filterExtensions = (list, type) => {
    if (type == "room") {
      return list.filter(
        (each) =>
          each.description?.match(/\d+,\d+,\d+,\d+/) &&
          each.description?.split(",")[0] > 0
      );
    }
  };

  fetchExtensionCount = (code, gateway, items) => {
    const data = items?.find((each) => each.code == code);
    const records = data?.data?.filter(
      (each) => each.description?.split(",")[0] == gateway
    );

    return records && records?.length > 0 ? records.length : "-";
  };

  renderProgress = (containerStyle) => {
    if (!this.state.loading) {
      return null;
    }

    const index = this.sites?.findIndex(
      (each) => each.id == this.state.currentSite?.code
    );

    return (
      <View
        style={[
          {
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: colors.lighestGray,
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 8,
          },
          containerStyle,
        ]}
      >
        <NtText
          style={{ color: colors.lighGray, fontSize: 14, fontWeight: "800" }}
        >
          Loading{"  "}
          <NtText style={{ color: colors.almostBlack }}>
            {this.state.currentSite?.code}
          </NtText>
          {"  "}
          {Math.round((index * 100) / this.sites.length)}%
        </NtText>
        <NtActivityIndicator
          containerStyle={{ marginLeft: 10 }}
          color={colors.blue}
          size="small"
        />
      </View>
    );
  };

  renderStartButton = (containerStyle) => {
    if (this.state.loading) {
      return null;
    }

    return (
      <NtTouchableEffect
        style={[
          {
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: "green",
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 20,
          },
          containerStyle,
        ]}
        onPress={() => {
          this.setState({ data: [], maxGateway: 0 });
          this.handleDataFetch();
        }}
      >
        <NtMaterialIcon name="play-circle" color="white" />
        <NtText
          style={{
            fontSize: 13,
            fontWeight: "600",
            color: "white",
            marginLeft: 8,
          }}
        >
          Start readin all sites
        </NtText>
      </NtTouchableEffect>
    );
  };

  renderRow = (item, index) => {
    return (
      <NtListRow
        key={index}
        item={item}
        columns={[{ id: "gateway", title: "Gateway" }, ...this.sites]}
        renderCustom={(item, column) => {}}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Gateway Report"}
        showFooter={true}
        showBackButton={true}
        maxWidth={"100%"}
      >
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 15 }}
        >
          <NtListRecordsTitle count={this.state.maxGateway} />

          {this.renderStartButton({ marginLeft: 35 })}
          {this.renderProgress({ marginLeft: 35 })}
        </View>

        <View style={{ flex: 1, marginTop: 15, marginBottom: 50 }}>
          <NtListHeader
            // visible={this.state.data?.length > 0}
            columns={[{ id: "gateway", title: "Gateway" }, ...this.sites]}
            selectedColumns={[
              { id: "gateway", title: "Gateway" },
              ...this.sites,
            ]}
            exportFileName={"gateway"}
            exportDataParser={this.handleExportDataParce}
            exportFetchDataAsync={this.handleExportFetch}
          />

          <NtListEmpty visible={this.state.data?.length == 0} />
          {this.state.data.map((each, index) => this.renderRow(each, index))}
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default GatewayReportController;
