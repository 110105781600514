import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { View, StyleSheet, ScrollView, FlatList } from "react-native";
import Logger from "../../common/utils/Logger";
import NtMessageUserItem from "./NtMessageUserItem";
import NtText from "../../components/NtText";
import NtDevider from "../../components/NtDevider";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtModal from "../../components/NtModal";
import NtDirectoryTabItem from "./NtDirectoryTabItem";
import { fetchConversations } from "../../api/chat";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import UserContext from "../../context/UserContext";
import NtMessageItemItem from "./NtMessageItem";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtMessageChannelCreateModal from "./NtMessageChannelCreateModal";
import { messagechanneltypes } from "../data/messagechanneltypes";
import settings from "../../config/settings";
import NtSearchBar from "../../components/NtSearchBar";
import {
  displayNameForRecord,
  parseChannelFromAddress,
} from "../utils/MessagingUtils";

function NtMessageUserDirectory({ onPress, directory }, ref) {
  const tabs = [
    {
      title: "Messages",
      id: "message",
      description: "conversation",
    },
    {
      title: "Directory",
      id: "directory",
      description: "contact",
    },
  ];

  const [data, setData] = useState([]);
  const [master, setMaster] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [searchText, setSearchText] = useState("");
  const context = useContext(UserContext);
  const newConversationRef = useRef();

  useImperativeHandle(ref, () => ({
    // https://stackoverflow.com/questions/43953791/calling-functions-from-other-components-in-react-native
    // each key is connected to `ref` as a method name
    // they can execute code directly, or call a local method
    // method1: () => { localMethod1() },

    reload: () => {
      if (selectedTab?.id == tabs[0].id) {
        handleDataFetch();
      }
    },
  }));

  const handleDataFetch = async (tab = selectedTab?.id) => {
    setLoading(true);

    if (tab == "directory") {
      setData(directory);
    } else if (tab == "message") {
      const response = await fetchConversations(
        context?.user?.username?.toString()
      );

      if (response.ok) {
        setData(response.data);
        setMaster(response.data);
      }
    }

    setLoading(false);
  };

  const handleSearch = (text) => {
    setSearchText(text);

    if (text) {
      if (selectedTab?.id == "directory") {
        setData(
          directory?.filter((each) =>
            each?.name?.toLowerCase()?.includes(text?.toLowerCase())
          )
        );
      } else if (selectedTab?.id == "message") {
        setData(
          master?.filter(
            (each) =>
              each?.mMessageText
                ?.toLowerCase()
                ?.includes(text?.toLowerCase()) ||
              displayNameForRecord(each?.mAddress, directory)
                .name?.toLowerCase()
                ?.includes(text?.toLowerCase())
          )
        );
      }
    } else {
      handleDataFetch();
    }
  };

  const handleChannelFetch = (channel) => {
    if (channel) {
      return messagechanneltypes.find((each) => each.id == channel);
    }
    return messagechanneltypes[0];
  };

  const handlePress = (address, channel) => {
    if (onPress) {
      onPress({
        address: address,
        channel: handleChannelFetch(channel),
      });
    }
    setSelected(address);
  };

  const renderNewConversationModal = () => {
    return (
      <NtModal ref={newConversationRef}>
        <NtMessageChannelCreateModal
          onCreate={(params) => {
            Logger("Params passed", params);
            let address = "";
            if (
              params?.destination?.length > 1 &&
              params.channel?.id == "app"
            ) {
              Logger("Preparing a group chat....");

              //will need to add myself to the destinations.....
              const myself = directory?.find(
                (each) => each.number == context.user?.username?.toString()
              );
              params.destination = [...params?.destination, myself];

              //will now sort
              params?.destination?.sort((a, b) => a.number - b.number);

              params?.destination?.map((each) => {
                address +=
                  each.number?.toString() + "@" + global.messagingService + ":";
              });

              address = address?.substring(0, address?.length - 1);
            } else if (params.channel?.id != "app") {
              Logger("Single channel ", params.channel?.id);
              address =
                params?.destination[0]?.externalNumber?.toString() +
                "@" +
                params?.channel?.service;
            } else {
              Logger("Single chat");
              address =
                params?.destination[0]?.number?.toString() +
                "@" +
                global.messagingService;
            }
            handlePress(address, params?.channel?.id);
            newConversationRef?.current?.dismiss();
          }}
          directory={directory}
          onCancel={() => {
            newConversationRef?.current?.dismiss();
          }}
        />
      </NtModal>
    );
  };

  const renderRow = (each, index) => {
    if (selectedTab?.id == "directory") {
      return (
        <NtMessageUserItem
          onPress={() => {
            handlePress(each.number, "app");
          }}
          key={each.id + selected}
          selected={each.number == selected}
          item={each}
        />
      );
    } else {
      return (
        <NtMessageItemItem
          onPress={() => {
            const channel = parseChannelFromAddress(each.mAddress);
            handlePress(each.mAddress, channel?.id);
          }}
          key={each.mMessageId + selected}
          selected={each.mAddress == selected}
          item={each}
          directory={directory}
        />
      );
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return null;
    }

    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NtActivityIndicator color={colors.darkest} />
      </View>
    );
  };

  const renderEmpty = () => {
    if (!(!loading && data?.length == 0)) {
      return null;
    }

    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <NtText
          style={{ color: colors.lighGray, fontWeight: "600", fontSize: 14 }}
        >
          No {selectedTab?.description}s available.
        </NtText>
      </View>
    );
  };

  useEffect(() => {
    handleDataFetch(tabs[0].id);
  }, []);

  const renderTabs = () => {
    return (
      <View style={{ flexDirection: "row", marginTop: 10 }}>
        <ScrollView
          contentContainerStyle={{ flexGrow: 1, paddingBottom: 10 }}
          showsHorizontalScrollIndicator={false}
          horizontal={true}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              height: 40,
            }}
          >
            {tabs.map((each, index) => (
              <NtDirectoryTabItem
                containerStyle={{ marginRight: 10 }}
                key={index}
                item={each}
                isSelected={each?.id == selectedTab?.id}
                onPress={() => {
                  setSelectedTab(each);
                  setData([]);
                  setSelected(null);
                  onPress(null);
                  handleDataFetch(each.id);
                  setSearchText("");
                }}
              />
            ))}
            <NtTouchableEffect
              style={{
                width: 25,
                height: 25,
                borderWidth: 1,
                borderColor: colors.almostBlack,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 15,
              }}
              onPress={() => {
                newConversationRef?.current?.show();
              }}
            >
              <NtMaterialIcon name="plus" color={colors.almostBlack} />
            </NtTouchableEffect>
          </View>
        </ScrollView>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View>
        <View style={{ padding: 15 }}>
          {renderTabs()}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <NtText style={styles.title}>
              {data?.length} {selectedTab?.description}
              {data?.length == 1 ? "" : "s"}
            </NtText>
            {loading && (
              <NtActivityIndicator
                containerStyle={{ marginLeft: 8 }}
                color={colors.darkest}
                size="small"
              />
            )}
          </View>
        </View>

        <NtSearchBar
          containerStyle={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}
          value={searchText}
          onChangeText={(text) => {
            handleSearch(text);
          }}
        />

        <NtDevider />
      </View>

      {/* {renderLoading()} */}
      {renderEmpty()}
      <ScrollView
        contentContainerStyle={{ paddingBottom: 0 }}
        showsVerticalScrollIndicator={false}
      >
        {data.map((each, index) => renderRow(each, index))}
      </ScrollView>
      {renderNewConversationModal()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 280,
    margin: 15,
    borderRadius: 15,
    backgroundColor: "white",
  },
  title: {
    color: colors.lighGray,
    fontSize: 16,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.lighGray,
    fontSize: 14,
    fontWeight: "600",
  },
});

export default forwardRef(NtMessageUserDirectory);
