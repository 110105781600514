const callingratescolumndata = [
  {
    id: "id",
    title: "ID",
  },
  {
    id: "destination",
    title: "Dial Prefix",
    default: true,
  },
  {
    id: "tariff",
    title: "Type",
    default: true,
  },
  {
    id: "cost",
    title: "Cost",
    default: true,
  },
  {
    id: "price",
    title: "Price",
    default: true,
  },
  {
    id: "name",
    title: "Name",
    default: true,
  },
  {
    id: "creationdate",
    title: "Creation Date",
  },
  {
    id: "status",
    title: "Status",
    default: true,
  },
];
export default callingratescolumndata;
