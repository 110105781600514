import React from "react";
import { View, StyleSheet } from "react-native";
import BaseController from "../../common/base/BaseController";
import Logger from "../../common/utils/Logger";
import { fetchExtensions } from "../../api/extension";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtText from "../../components/NtText";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import settings from "../../config/settings";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtListRecordsTitle from "../../components/NtListRecordsTitle";
import NtListHeader from "../../components/NtListHeader";
import extensioncsvcolumndata from "../data/extensioncsvcolumndata";
import { asyncStorageSetData } from "../../common/utils/ControllerUtils";
import NtListRow from "../../components/NtListRow";
import { isPhysicalExtension, parseExtension } from "../utils/ExtensionUtils";
import NtListEmpty from "../../components/NtListEmpty";

class GatewayController extends BaseController {
  state = {
    ...super.state,
    loading: false,
    data: [],
    totalItems: 0,
    searchText: "",
  };

  column_location = "@gateway_column_storage";

  page = 1;

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.handleDefaultColumnsFetch(
      this.column_location,
      extensioncsvcolumndata.filter((each) => each.default == true)
    );

    this.handleDataFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
    this.handleAccessControl(settings.modulePbx);
  };

  handleDataFetch = async () => {
    Logger("Fetching Extensions data");

    this.setState({ loading: true });

    const response = await fetchExtensions(
      this.context.user?.environment?.domain,
      this.page,
      settings.pageSizeLoad,
      settings.accountPrefixPhy,
      this.state.searchText,
      { gateway: this.props.route?.params?.gateway }
    );

    Logger("Data response GET: ", response);

    if (response.ok) {
      Logger("Loading data total items", response.data?.extensions?.length);

      if (this.page == 1) {
        Logger("Loading for the first time.");
        this.setState({
          data: response.data?.extensions,
          totalItems: response.data?.total,
        });
      } else {
        Logger("Loading more.");

        this.setState({
          data: this.state.data.concat(response.data?.extensions),
        });
      }
    }
    this.setState({ loading: false });
  };

  handleExportFetch = async () => {
    Logger("Fetching Calling Rates export data");

    const response = await fetchExtensions(
      this.context.user?.environment?.domain,
      1,
      10000000,
      settings.accountPrefixPhy,
      this.state.searchText,
      { gateway: this.props.route?.params?.gateway }
    );

    Logger("Data response GET: ", response);

    if (response.ok) {
      //will filter base on the tab....
      return response.data.extensions;
    }
    return [];
  };

  handleExportDataParce = (item, column) => {
    if (column == "extension") {
      return parseExtension(item[column]);
    } else if (column == "description") {
      if (isPhysicalExtension(item[column])) {
        return `"Gateway: ${item[column]?.split(",")[0]}, Port: ${
          item[column]?.split(",")[1]
        }"`;
      }
    } else if (column == "toll_allow") {
      return item[column] && item[column] == "t" ? "True" : "False";
    }
    return item[column];
  };

  handleSearch = (text) => {
    this.state.searchText = text;
    this.setState({ searchText: this.state.searchText });
    this.page = 1;
    this.handleDataFetch();
  };

  renderViewGateway = (containerStyle) => {
    return (
      <NtTouchableEffect
        style={[
          {
            backgroundColor: colors.blue,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 4,
            paddingBottom: 4,
            borderRadius: 15,
          },
          containerStyle,
        ]}
        onPress={() => {
          const url = `http://10.26.${this.context.user.environment.octet}.${
            80 + parseInt(this.props.route?.params?.gateway)
          }`;
          Logger("Will load the following URL: ", url);
          window.open(url);
        }}
      >
        <NtText style={{ fontSize: 13, fontWeight: "600", color: "white" }}>
          View Gateway
        </NtText>
      </NtTouchableEffect>
    );
  };

  renderRow = (item, index) => {
    return (
      <NtListRow
        key={item.extension_uuid}
        item={item}
        columns={this.state.columns}
        renderCustom={(item, column) => {
          if (column == "description") {
            return `Port: ${item[column]?.split(",")[1]}`;
          } else if (column == "extension") {
            return parseExtension(item[column]);
          } else if (column == "toll_allow") {
            return item[column] && item[column] == "t" ? "True" : "False";
          }
        }}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={`Gateway ${this.props.route?.params?.gateway}`}
        showFooter={true}
        showBackButton={true}
        searchText={this.state.searchText}
        setSearchText={(text) => {
          this.handleSearch(text);
        }}
        maxWidth={"100%"}
      >
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 15 }}
        >
          <NtListRecordsTitle count={this.state.totalItems} />

          {this.renderViewGateway({ marginLeft: 25 })}
        </View>

        <View
          style={{
            flex: 1,
            marginTop: 15,
          }}
        >
          <NtListHeader
            visible={this.state.data?.length > 0}
            columns={extensioncsvcolumndata}
            selectedColumns={this.state.columns}
            onColumnUpdate={(updated) => {
              asyncStorageSetData(this.column_location, updated);
              this.setState({ columns: updated });
            }}
            exportFileName={"gateway"}
            exportDataParser={this.handleExportDataParce}
            exportFetchDataAsync={this.handleExportFetch}
          />
          <NtListEmpty visible={this.state.data?.length == 0} />
          {this.state.data.map((each, index) => this.renderRow(each, index))}

          {
            <View>
              <NtLoadMoreButton
                loadingText={"Loading"}
                title="Load More Extensions"
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  this.page = this.page + 1;
                  this.handleDataFetch();
                }}
              />
            </View>
          }
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default GatewayController;
