import React, { useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import NtDevider from "../../components/NtDevider";
import colors from "../../config/colors";
import Logger from "../../common/utils/Logger";
import { parseDecimalPlaces } from "../../common/utils/ControllerUtils";

function NtDashboardRow({ item, columns, textStyle }) {
  const renderRow = (column) => {
    if (column?.id == "total_cost" || column?.id == "total_billed") {
      return (
        <View
          style={{
            flex: 1,
            alignItems: "flex-start",
            justifyContent: "center",
          }}
          key={column.id}
        >
          {item[column.id] && (
            <NtText style={[styles.text, textStyle]}>
              ${parseDecimalPlaces(item[column.id])}
            </NtText>
          )}
        </View>
      );
    }
    return (
      <View
        style={{ flex: 1, alignItems: "flex-start", justifyContent: "center" }}
        key={column.id}
      >
        <NtText style={[styles.text, textStyle]}>{item[column.id]}</NtText>
      </View>
    );
  };

  if (!item) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          padding: 6,
        }}
      >
        {columns?.map((each) => renderRow(each))}
      </View>
      <NtDevider />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    fontSize: 13,
    fontWeight: "800",
    color: colors.lighGray,
  },
});

export default NtDashboardRow;
