import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";

function NtCallingRatesStatItem({
  title,
  value,
  containerStyle,
  showSlashMin = true,
}) {
  return (
    <View style={[styles.container, containerStyle]}>
      <NtText style={[styles.title, { marginTop: 10 }]}>{title}</NtText>
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <NtText style={styles.value}>
          {value}
          {showSlashMin ? <NtText style={{ fontSize: 11 }}>/min</NtText> : ""}
        </NtText>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 100,
    backgroundColor: colors.lighestGray,
    borderRadius: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
  title: {
    fontSize: 13,
    fontWeight: "700",
    color: colors.lighGray,
    textAlign: "center",
  },
  value: {
    fontSize: 24,
    fontWeight: "700",
    color: colors.lighGray,
    textAlign: "center",
  },
});

export default NtCallingRatesStatItem;
