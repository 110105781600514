import Logger from "../common/utils/Logger";
import client from "./client";

export const fetchRingGroups = (domain, page, pageSize, search, filter) => {
  let endpoint = `/v2/pbx/domain/${domain}/ringgroup?page=${page}&pagesize=${pageSize}&withExtraInfo=true`;

  if (search) {
    endpoint += `&search=${search}`;
  }

  if (filter?.extension) {
    endpoint += `&ring_group_extension=${filter?.extension}`;
  }
  if (filter?.callerIdName) {
    endpoint += `&ring_group_caller_id_name=${filter?.callerIdName}`;
  }
  if (filter?.callerIdNumber) {
    endpoint += `&ring_group_caller_id_number=${filter?.callerIdNumber}`;
  }
  if (filter?.callTimeout) {
    endpoint += `&ring_group_call_timeout=${filter?.callTimeout}`;
  }
  if (filter?.strategy) {
    endpoint += `&ring_group_strategy=${filter?.strategy}`;
  }

  //sort
  if (filter?.orderBy) {
    endpoint += `&orderby=insert_date&order=${filter?.orderBy}`;
  } else {
    endpoint += `&orderby=insert_date&order=DESC`;
  }

  Logger("Final endpoint for requesting ringgroups: ", endpoint);

  return client.get(endpoint);
};

export const fetchRingGroup = (domain, id) => {
  let endpoint = `/v2/pbx/domain/${domain}/ringgroup/${id}`;

  return client.get(endpoint);
};

export const fetchRingGroupDestinations = (
  domain,
  ringgroup,
  page,
  pageSize
) => {
  let endpoint = `/v2/pbx/domain/${domain}/ringgroup/${ringgroup}/destination?page=${page}&pagesize=${pageSize}`;

  Logger("Final endpoint for requesting ringgroup destinations: ", endpoint);

  return client.get(endpoint);
};

export const createRingGroupDestination = (domain, ringgroup, params) => {
  let endpoint = `/v2/pbx/domain/${domain}/ringgroup/${ringgroup}/destination`;

  const body = {
    destination_number: params?.destination_number,
    destination_delay: params?.destination_delay,
    destination_timeout: params?.destination_timeout,
  };

  return client.post(endpoint, body);
};

export const deleteRingGroupDestination = (domain, ringgroup, id) => {
  let endpoint = `/v2/pbx/domain/${domain}/ringgroup/${ringgroup}/destination/${id}`;

  return client.delete(endpoint);
};

export const updateRingGroup = (domain, id, params) => {
  let endpoint = `/v2/pbx/domain/${domain}/ringgroup/${id}`;

  let body = {
    ring_group_call_timeout: params?.ring_group_call_timeout,
    ring_group_strategy: params?.ring_group_strategy,
    ring_group_caller_id_name: params?.ring_group_caller_id_name,
    ring_group_caller_id_number: params?.ring_group_caller_id_number,
  };

  if (params?.ring_group_timeout_action) {
    body.ring_group_timeout_action = params.ring_group_timeout_action;
  }

  return client.patch(endpoint, body);
};

export const createRingGroup = (domain, params) => {
  let endpoint = `/v2/pbx/domain/${domain}/ringgroup`;

  const body = {
    ring_group_extension: params?.ring_group_extension,
    ring_group_call_timeout: params?.ring_group_call_timeout,
    ring_group_strategy: params?.ring_group_strategy,
    ring_group_name: params?.ring_group_name,
  };

  if (params?.ring_group_timeout_action) {
    body.ring_group_timeout_action = params.ring_group_timeout_action;
  }

  return client.post(endpoint, body);
};

export const deleteRingGroup = (domain, id) => {
  let endpoint = `/v2/pbx/domain/${domain}/ringgroup/${id}`;

  return client.delete(endpoint);
};
