import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtMessageUserDirectory from "../components/NtMessageUserDirectory";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtMessageChatInputController from "../components/NtMessageChatInputController";
import NtConnectionStatus from "../components/NtConnectionStatus";
import Logger from "../../common/utils/Logger";
import MessagingClient from "../utils/MessagingClient";
import BaseController from "../../common/base/BaseController";
import MessageItem from "../utils/Message/MessageItem";
import RecordItem from "../utils/Message/RecordItem";
import NtchatItem from "../components/NtchatItem";
import NtDevider from "../../components/NtDevider";
import { fetchHistory } from "../../api/chat";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import { fetchDirectory } from "../../api/directory";
import { displayNameForRecord } from "../utils/MessagingUtils";
import NtChannelIcon from "../components/NtChannelIcon";
import NtBackButton from "../../root/components/NtBackButton";
import IQItem from "../utils/Message/IQItem";
import settings from "../../config/settings";

class MessageV2Controller extends BaseController {
  state = {
    data: [],
    directory: [],
    user: null,
    destination: null,
    loading: false,
  };

  scrollViewRef = React.createRef();
  inputRef = React.createRef();
  userDirectoryRef = React.createRef();

  client = new MessagingClient();

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    if (this.client.isConnected) {
      this.handleSubscribe();
    } else {
      this.client.addConnectionCallback((connection) => {
        Logger("Connection callback: ", connection);
        if (connection.connected) {
          this.handleSubscribe();
        }
      });
    }

    this.handleDirectoryFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
    this.handleAccessControl(settings.moduleOmniChannel);
  };

  handleSubscribe = () => {
    this.client.subscribe(this.context.user, this.onMessageReceived);
    this.setState({ isOnline: true });
  };

  handleDirectoryFetch = async () => {
    const response = await fetchDirectory(
      this.context.user?.environment?.domain
    );
    if (response.ok) {
      this.setState({ directory: response.data });
      Logger("Just loaded the directory", response.data?.length);
    }
  };

  handleHistoryFetch = async () => {
    this.setState({ loading: true });

    const user = new RecordItem(this.context.user.username.toString());
    const address = new RecordItem(this.state.destination?.address);

    const response = await fetchHistory(
      user.getAddress(),
      address.getAddress()
    );
    if (response.ok) {
      this.setState({ data: response.data });
    }

    this.setState({ loading: false });
  };

  handleMessageSend = (messageText) => {
    this.inputRef?.current?.setLoading(true);

    const record = new RecordItem(this.state.destination?.address);
    const message = new MessageItem(
      this.context.user,
      record.getAddress(),
      messageText
    );
    this.client.sendMessage(message, record, this.onMessageSent);
  };

  handleReadReceipt = () => {
    const user = new RecordItem(this.context.user.username.toString());
    const iq = new IQItem(
      "",
      user.getAddress(),
      this.state.destination?.address,
      null
    );
    iq.setStatus(5); //read
    iq.setType(1); //address change
    this.client.sendIQ(iq);
  };

  onMessageSent = (message) => {
    Logger("Message sent", message);
    this.setState({
      messageText: "",
      data: [...this.state.data, message],
      isSending: false,
    });

    this.inputRef?.current?.setLoading(false);
    this.inputRef?.current?.clear();

    //finally update the conversation view
    setTimeout(() => {
      this.userDirectoryRef?.current?.reload();
    }, 4000);
  };

  onMessageReceived = (message) => {
    Logger(
      "Message Received ",
      message,
      this.state.destination?.address,
      message.mAddress
    );

    if (this.state.destination?.address == message.mAddress) {
      this.setState({
        data: [...this.state.data, message],
      });

      this.handleReadReceipt();
    }

    setTimeout(() => {
      this.userDirectoryRef?.current?.reload();
    }, 4000);
  };

  renderHeader = () => {
    return (
      <View style={{}}>
        <View style={{ flex: 1, padding: 15 }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <NtBackButton containerStyle={{ marginRight: 10 }} />
            {this.state.destination && (
              <NtChannelIcon
                containerStyle={{ marginRight: 6 }}
                channel={this.state.destination?.channel}
              />
            )}
            <NtText
              style={{ color: colors.darkest, fontSize: 18, fontWeight: "600" }}
            >
              {this.state.destination?.address
                ? displayNameForRecord(
                    this.state.destination?.address,
                    this.state.directory,
                    this.context?.user?.username?.toString()
                  ).name
                : "Select user to start conversation"}
            </NtText>
            {this.state.loading && (
              <NtActivityIndicator
                color={colors.blue}
                size="small"
                containerStyle={{ marginLeft: 10 }}
              />
            )}
          </View>

          <NtText
            style={{
              color: colors.lighGray,
              fontSize: 14,
              fontWeight: "600",
              marginTop: 6,
            }}
          >
            Connection Status
            <NtConnectionStatus containerStyle={{ marginLeft: 6 }} />
          </NtText>
        </View>
        <NtDevider />
      </View>
    );
  };

  renderRow = (item, index) => {
    return <NtchatItem key={item.mMessageId + index} item={item} />;
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={{ flex: 1, flexDirection: "row" }}>
          <NtMessageUserDirectory
            ref={this.userDirectoryRef}
            onPress={(destination) => {
              this.state.destination = destination;
              this.setState({ destination: this.state.destination, data: [] });
              if (this.state.destination?.address) {
                this.handleHistoryFetch();
                this.handleReadReceipt();
              }
            }}
            directory={this.state.directory}
          />
          {/* Chat */}
          <View
            style={{
              flex: 1,
              backgroundColor: "white",
              borderRadius: 15,
              marginTop: 15,
              marginRight: 15,
              marginBottom: 15,
            }}
          >
            {this.renderHeader()}
            <ScrollView
              ref={this.scrollViewRef}
              contentContainerStyle={{
                flexGrow: 1,
                padding: 10,
              }}
              showsVerticalScrollIndicator={false}
              onContentSizeChange={() => {
                if (!this.allowScrollToBottom) {
                  return;
                }
                this.scrollViewRef?.current.scrollToEnd({
                  animated: this.firstTime ? false : true,
                });
                if (this.state.data.length > 0 && this.firstTime) {
                  this.firstTime = false;
                }
              }}
            >
              {this.state.data.map((each, index) =>
                this.renderRow(each, index)
              )}
            </ScrollView>

            {this.state.destination?.address && (
              <NtMessageChatInputController
                ref={this.inputRef}
                containerStyle={{ margin: 10 }}
                onSend={(message) => {
                  this.handleMessageSend(message);
                }}
                enabled={this.state.destination?.address}
              />
            )}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  headerText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.lighGray,
  },
});

export default MessageV2Controller;
