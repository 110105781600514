import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import Logger from "../../common/utils/Logger";
import { fetchExtensions } from "../../api/extension";
import settings from "../../config/settings";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import NtExtensionItem from "../components/NtExtensionItem";
import colors from "../../config/colors";
import routes from "../../navigation/routes";
import BaseController from "../../common/base/BaseController";
import NtPageActionButton from "../../components/NtPageActionButton";
import NtExtensionUploadForm from "../components/NtExtensionUploadForm";
import NtModal from "../../components/NtModal";
import NtExtensionNewForm from "../components/NtExtensionNewForm";
import {
  asyncStorageSetData,
  showToast,
} from "../../common/utils/ControllerUtils";
import extensioncsvcolumndata from "../data/extensioncsvcolumndata";
import NtListHeader from "../../components/NtListHeader";
import NtExtensionAdvancedSearchPicker from "../components/NtExtensionAdvanceSearchPicker";
import { isPhysicalExtension, parseExtension } from "../utils/ExtensionUtils";
import NtListRecordsTitle from "../../components/NtListRecordsTitle";
import NtListEmpty from "../../components/NtListEmpty";

class ExtensionController extends BaseController {
  state = {
    ...super.state,
    loading: false,
    data: [],
    totalItems: 0,
    searchText: "",
    searchFilter: null,
  };

  column_location = "@extensions_column_storage";

  page = 1;

  addAccessModalRef = React.createRef();
  uploadAccessModalRef = React.createRef();
  advanceSearchRef = React.createRef();

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.handleDefaultColumnsFetch(
      this.column_location,
      extensioncsvcolumndata.filter((each) => each.default == true)
    );

    this.handleDataFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
    this.handleAccessControl(settings.modulePbx);
  };

  handleDataFetch = async () => {
    Logger("Fetching Extensions data");

    this.setState({ loading: true });

    const response = await fetchExtensions(
      this.context.user?.environment?.domain,
      this.page,
      settings.pageSizeLoad,
      settings.accountPrefixPhy,
      this.state.searchText,
      this.state.searchFilter
    );

    Logger("Data response GET: ", response);

    if (response.ok) {
      Logger("Loading data total items", response.data?.extensions?.length);

      if (this.page == 1) {
        Logger("Loading for the first time.");
        this.setState({
          data: response.data?.extensions,
          totalItems: response.data?.total,
        });
      } else {
        Logger("Loading more.");

        this.setState({
          data: this.state.data.concat(response.data?.extensions),
        });
      }
    }
    this.setState({ loading: false });
  };

  handleExportFetch = async () => {
    Logger("Fetching Extensions export data");

    const response = await fetchExtensions(
      this.context.user?.environment?.domain,
      1,
      10000000,
      settings.accountPrefixPhy,
      this.state.searchText,
      this.state.searchFilter
    );

    Logger("Data response GET: ", response);

    if (response.ok) {
      //will filter base on the tab....
      return response.data.extensions;
    }
    return [];
  };

  handleExportDataParce = (item, column) => {
    if (column == "extension") {
      return parseExtension(item[column]);
    } else if (column == "description") {
      if (isPhysicalExtension(item[column])) {
        return `"Gateway: ${item[column]?.split(",")[0]}, Port: ${
          item[column]?.split(",")[1]
        }"`;
      }
    } else if (column == "toll_allow") {
      return item[column] && item[column] == "t" ? "True" : "False";
    }
    return item[column];
  };

  handleSearch = (text) => {
    this.state.searchText = text;
    this.setState({ searchText: this.state.searchText });
    this.page = 1;
    this.handleDataFetch();
  };

  renderAdvanceSearchForm = () => {
    return (
      <NtModal ref={this.advanceSearchRef}>
        <NtExtensionAdvancedSearchPicker
          filter={this.state.searchFilter}
          onCancel={() => {
            this.advanceSearchRef.current?.dismiss();
          }}
          onFilter={(filter) => {
            this.state.searchFilter = filter;
            this.setState({ searchFilter: this.state.searchFilter });
            this.advanceSearchRef.current?.dismiss();

            this.page = 1;
            this.handleDataFetch();
          }}
          onFilterClear={() => {
            this.state.searchFilter = null;
            this.setState({ searchFilter: this.state.searchFilter });
            this.advanceSearchRef.current?.dismiss();

            this.page = 1;
            this.handleDataFetch();
          }}
        />
      </NtModal>
    );
  };

  renderAddNewForm = () => {
    return (
      <NtModal ref={this.addAccessModalRef}>
        <NtExtensionNewForm
          onCancel={() => {
            this.addAccessModalRef.current?.dismiss();
          }}
          onCreate={() => {
            this.page = 1;
            this.handleDataFetch();
            this.addAccessModalRef.current?.dismiss();
            showToast("Success", "Successfully created the new extension");
          }}
        />
      </NtModal>
    );
  };

  renderUploadForm = () => {
    return (
      <NtModal ref={this.uploadAccessModalRef}>
        <NtExtensionUploadForm
          onCancel={() => {
            this.uploadAccessModalRef.current?.dismiss();
          }}
          onUploaded={() => {
            this.page = 1;
            this.handleDataFetch();
            this.uploadAccessModalRef.current?.dismiss();
          }}
        />
      </NtModal>
    );
  };

  renderRow = (item, index) => {
    return (
      <NtExtensionItem
        key={index}
        item={item}
        columns={this.state.columns}
        onGatewayPress={(gateway) => {
          this.props.navigation.navigate(routes.gateway, { gateway: gateway });
        }}
        onUpdate={() => {
          this.page = 1;
          this.handleDataFetch();
          showToast(
            "Success",
            `Successfully updated extension ${parseExtension(item.extension)}`
          );
        }}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Extensions"}
        showFooter={true}
        showBackButton={true}
        searchText={this.state.searchText}
        searchFilterCount={
          this.state.searchFilter
            ? Object.keys(this.state.searchFilter)?.length
            : 0
        }
        setSearchText={(text) => {
          this.handleSearch(text);
        }}
        onSearchFilterPress={() => {
          this.advanceSearchRef?.current?.show();
        }}
        maxWidth={"100%"}
      >
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 15 }}
        >
          <NtListRecordsTitle count={this.state.totalItems} />

          <NtPageActionButton
            icon={"plus"}
            onPress={() => {
              this.addAccessModalRef.current?.show();
            }}
            containerStyle={{ marginLeft: 15 }}
          />
          <NtPageActionButton
            icon={"arrow-up"}
            onPress={() => {
              this.uploadAccessModalRef.current?.show();
            }}
            containerStyle={{ marginLeft: 10 }}
          />
        </View>

        <View
          style={{
            flex: 1,
            marginTop: 15,
          }}
        >
          <NtListHeader
            visible={this.state.data?.length > 0}
            headerContainerStyle={{ marginRight: 35 }}
            columns={extensioncsvcolumndata}
            selectedColumns={this.state.columns}
            onColumnUpdate={(updated) => {
              asyncStorageSetData(this.column_location, updated);
              this.setState({ columns: updated });
            }}
            exportFileName={"extension"}
            exportDataParser={this.handleExportDataParce}
            exportFetchDataAsync={this.handleExportFetch}
          />

          <NtListEmpty visible={this.state.data?.length == 0} />
          {this.state.data.map((each, index) => this.renderRow(each, index))}

          {
            <View>
              <NtLoadMoreButton
                loadingText={"Loading"}
                title="Load More Extensions"
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  this.page = this.page + 1;
                  this.handleDataFetch();
                }}
              />
            </View>
          }
        </View>
        {this.renderAddNewForm()}
        {this.renderUploadForm()}
        {this.renderAdvanceSearchForm()}
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  title: {
    color: colors.lighGray,
    fontWeight: "500",
    fontSize: 15,
  },
});

export default ExtensionController;
