import React, { useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import {
  parseDecimalPlaces,
  parseSimpleDisplayDate,
} from "../../common/utils/ControllerUtils";
import NtPinItem from "./NtPinItem";
import NtModal from "../../components/NtModal";
import NtPinManagementUpdateForm from "./NtPinManagementUpdateForm";
import moment from "moment-timezone";
import NtListRow from "../../components/NtListRow";

function NtPinManagementItem({ item, onUpdate, columns, onReset }) {
  const updateModalRef = useRef();

  const renderUpdateForm = () => {
    return (
      <NtModal ref={updateModalRef}>
        <NtPinManagementUpdateForm
          item={item}
          onCancel={() => {
            updateModalRef.current?.dismiss();
          }}
          onUpdate={() => {
            updateModalRef.current?.dismiss();
            if (onUpdate) {
              onUpdate();
            }
          }}
          onReset={() => {
            updateModalRef.current?.dismiss();
            if (onReset) {
              onReset();
            }
          }}
        />
      </NtModal>
    );
  };

  return (
    <View style={styles.container}>
      <NtListRow
        item={item}
        columns={columns}
        renderCustom={(item, column) => {
          if (column == "id") {
            // this is for pin.....
            return (
              <NtPinItem number={item[column]} enabled={item.active == 1} />
            );
          } else if (column == "credit" || column == "initialbalance") {
            return item.tariff == "admin"
              ? "N/A"
              : `$${parseDecimalPlaces(item[column])}`;
          } else if (column == "expirationdate") {
            return (
              <NtText
                style={[
                  {
                    color:
                      moment(item[column]) < new Date() && item.expirationdate
                        ? "red"
                        : colors.lighGray,
                  },
                ]}
              >
                {item.expirationdate
                  ? parseSimpleDisplayDate(item[column])
                  : "N/A"}
              </NtText>
            );
          } else if (column == "creationdate") {
            return parseSimpleDisplayDate(item[column]);
          } else if (column == "lastuse") {
            return item[column] ? parseSimpleDisplayDate(item[column]) : "";
          } else if (column == "status") {
            return item[column] == 1 ? "Active" : "Inactive";
          }
        }}
        onActionPress={() => {
          updateModalRef.current?.show();
        }}
      />

      {renderUpdateForm()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtPinManagementItem;
