import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "./NtText";

function NtModalError({ containerStyle, error }) {
  if (!error) {
    return null;
  }

  return (
    <View style={[styles.container, containerStyle]}>
      <NtText style={styles.text}>{error}</NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    color: "red",
    fontSize: 12,
    fontWeight: "600",
  },
});

export default NtModalError;
