import client from "./client";

export const fetchAppAccess = (page, pageSize) => {
  return client.get(`/v1/appaccess?page=${page}&pagesize=${pageSize}`);
};

export const deleteAppAccess = (id) => {
  return client.delete(`/v1/appaccess/${id}`);
};

export const createAppAccess = (extension, email) => {
  const body = {
    extension: extension,
    email: email,
    preference: "email",
  };
  return client.post(`/v1/appaccess`, body);
};
