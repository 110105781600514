import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtDevider from "../../components/NtDevider";

function NtLoginButtonTypePicker({ types, selected, onPress }) {
  const renderRow = (item, index) => {
    return (
      <NtTouchableEffect
        key={index}
        onPress={() => {
          if (onPress) {
            onPress(item);
          }
        }}
      >
        <View
          style={{
            paddingTop: 15,
            paddingBottom: 15,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <NtMaterialIcon name={item.icon} color={colors.darkest} />
          <NtText style={[styles.rowTitle, { flex: 1, marginLeft: 10 }]}>
            {item.title}
          </NtText>

          {item.id == selected?.id && (
            <NtMaterialIcon name="check" color={colors.darkest} />
          )}
        </View>

        <NtDevider />
      </NtTouchableEffect>
    );
  };

  return (
    <View style={styles.container}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <NtText style={[styles.title, { marginRight: 10 }]}>
            Login Type
          </NtText>
        </View>

        {types?.map((each, index) => renderRow(each, index))}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: 250,
    padding: 15,
    maxHeight: 450,
  },
  rowTitle: {
    color: colors.darkest,
    fontSize: 14,
    fontWeight: "600",
  },
  title: {
    color: colors.darkest,
    fontSize: 17,
    fontWeight: "700",
  },
});

export default NtLoginButtonTypePicker;
