import React from "react";
import { View, StyleSheet } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import BaseController from "../../common/base/BaseController";
import NtListRecordsTitle from "../../components/NtListRecordsTitle";
import NtPageActionButton from "../../components/NtPageActionButton";
import NtListHeader from "../../components/NtListHeader";
import {
  asyncStorageSetData,
  showToast,
} from "../../common/utils/ControllerUtils";
import NtListEmpty from "../../components/NtListEmpty";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import ringgroupcsvcolumndata from "../data/ringgroupcsvcolumndata";
import Logger from "../../common/utils/Logger";
import settings from "../../config/settings";
import { fetchRingGroups } from "../../api/ringgroup";
import NtRingGroupItem from "../components/NtRingGroupItem";
import NtModal from "../../components/NtModal";
import NtRingGroupAdvancedSearchPicker from "../components/NtRingGroupAdvanceSearchPicker";
import NtRingGroupNewForm from "../components/NtRingGroupNewForm";

class RingGroupController extends BaseController {
  state = {
    ...super.state,
    loading: false,
    data: [],
    totalItems: 0,
    searchText: "",
    searchFilter: null,
  };

  column_location = "@ringgroup_column_storage";

  page = 1;

  addAccessModalRef = React.createRef();
  advanceSearchRef = React.createRef();

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.handleDefaultColumnsFetch(
      this.column_location,
      ringgroupcsvcolumndata.filter((each) => each.default == true)
    );

    this.handleDataFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
    this.handleAccessControl(settings.modulePbx);
  };

  handleDataFetch = async () => {
    Logger("Fetching RingGroups data");
    this.setState({ loading: true });
    const response = await fetchRingGroups(
      this.context.user?.environment?.domain,
      this.page,
      settings.pageSizeLoad,
      this.state.searchText,
      this.state.searchFilter
    );
    Logger("Data response GET: ", response);
    if (response.ok) {
      Logger("Loading data total items", response.data?.ringgroups?.length);
      if (this.page == 1) {
        Logger("Loading for the first time.");
        this.setState({
          data: response.data?.ringgroups,
          totalItems: response.data?.total,
        });
      } else {
        Logger("Loading more.");
        this.setState({
          data: this.state.data.concat(response.data?.ringgroups),
        });
      }
    }
    this.setState({ loading: false });
  };

  handleExportFetch = async () => {
    Logger("Fetching Ring Groups export data");

    const response = await fetchRingGroups(
      this.context.user?.environment?.domain,
      1,
      10000000,
      this.state.searchText,
      this.state.searchFilter
    );

    Logger("Data response GET: ", response);

    if (response.ok) {
      //will filter base on the tab....
      return response.data.ringgroups;
    }
    return [];
  };

  handleExportDataParce = (item, column) => {
    if (column == "ring_group_call_timeout") {
      return `${item[column]}s`;
    } else if (
      column == "ring_group_enabled" ||
      column == "ring_group_forward_enabled"
    ) {
      return item[column] ? "Enabled" : "Disabled";
    } else if (column == "destination_numbers") {
      let destinations = "";
      item[column]?.map((each) => {
        destinations += `${each},`;
      });

      if (destinations?.length > 0) {
        destinations = destinations?.substring(0, destinations?.length - 1);
      }

      return `"${destinations}"`;
    }

    return item[column];
  };

  handleSearch = (text) => {
    this.state.searchText = text;
    this.setState({ searchText: this.state.searchText });
    this.page = 1;
    this.handleDataFetch();
  };

  renderAdvanceSearchForm = () => {
    return (
      <NtModal ref={this.advanceSearchRef}>
        <NtRingGroupAdvancedSearchPicker
          filter={this.state.searchFilter}
          onCancel={() => {
            this.advanceSearchRef.current?.dismiss();
          }}
          onFilter={(filter) => {
            this.state.searchFilter = filter;
            this.setState({ searchFilter: this.state.searchFilter });
            this.advanceSearchRef.current?.dismiss();

            this.page = 1;
            this.handleDataFetch();
          }}
          onFilterClear={() => {
            this.state.searchFilter = null;
            this.setState({ searchFilter: this.state.searchFilter });
            this.advanceSearchRef.current?.dismiss();

            this.page = 1;
            this.handleDataFetch();
          }}
        />
      </NtModal>
    );
  };

  renderAddNewForm = () => {
    return (
      <NtModal ref={this.addAccessModalRef}>
        <NtRingGroupNewForm
          onCancel={() => {
            this.addAccessModalRef.current?.dismiss();
          }}
          onCreate={() => {
            this.page = 1;
            this.handleDataFetch();
            this.addAccessModalRef.current?.dismiss();
            showToast("Success", "Successfully created the new extension");
          }}
        />
      </NtModal>
    );
  };

  renderRow = (item, index) => {
    return (
      <NtRingGroupItem
        key={index}
        item={item}
        columns={this.state.columns}
        onUpdate={() => {
          this.page = 1;
          this.handleDataFetch();
          showToast(
            "Success",
            `Successfully updated Ring Group ${item?.ring_group_extension}`
          );
        }}
        onDelete={() => {
          this.page = 1;
          this.handleDataFetch();
          showToast(
            "Success",
            `Successfully deleted Ring Group ${item?.ring_group_extension}`
          );
        }}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Ring Groups"}
        showFooter={true}
        showBackButton={true}
        searchText={this.state.searchText}
        setSearchText={(text) => {
          this.handleSearch(text);
        }}
        searchFilterCount={
          this.state.searchFilter
            ? Object.keys(this.state.searchFilter)?.length
            : 0
        }
        onSearchFilterPress={() => {
          this.advanceSearchRef?.current?.show();
        }}
        maxWidth={"100%"}
      >
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}
        >
          <NtListRecordsTitle count={this.state.totalItems} />

          <NtPageActionButton
            icon={"plus"}
            onPress={() => {
              this.addAccessModalRef.current?.show();
            }}
            containerStyle={{ marginLeft: 15 }}
          />
        </View>

        <View
          style={{
            flex: 1,
            marginTop: 15,
          }}
        >
          <NtListHeader
            visible={this.state.data?.length > 0}
            headerContainerStyle={{ marginRight: 35 }}
            columns={ringgroupcsvcolumndata}
            selectedColumns={this.state.columns}
            onColumnUpdate={(updated) => {
              asyncStorageSetData(this.column_location, updated);
              this.setState({ columns: updated });
            }}
            exportFileName={"ringgroup"}
            exportDataParser={this.handleExportDataParce}
            exportFetchDataAsync={this.handleExportFetch}
          />
          <NtListEmpty visible={this.state.data?.length == 0} />
          {this.state.data.map((each, index) => this.renderRow(each, index))}

          {
            <View>
              <NtLoadMoreButton
                loadingText={"Loading"}
                title="Load More Records"
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  this.page = this.page + 1;
                  this.handleDataFetch();
                }}
              />
            </View>
          }
        </View>
        {this.renderAddNewForm()}
        {this.renderAdvanceSearchForm()}
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default RingGroupController;
