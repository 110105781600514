const ringgroupdestinationscsvcolumndata = [
  {
    id: "destination_number",
    title: "Destination",
    default: true,
  },
  {
    id: "destination_delay",
    title: "Delay",
    default: true,
  },
  {
    id: "destination_timeout",
    title: "Timeout",
    default: true,
  },
];

export default ringgroupdestinationscsvcolumndata;
