import React from "react";
import { View, StyleSheet } from "react-native";
import Logger from "../../common/utils/Logger";
import AuthNavigator from "../../navigation/AuthNavigator";
import RootController from "./RootController";
import settings from "../../config/settings";

class RootAuthController extends RootController {
  state = {
    currentController: null,
    isMobile: false,
  };

  componentDidMount() {
    super.componentDidMount();

    window.$crisp = [];
    window.CRISP_WEBSITE_ID = settings.cripsAppId;

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }

  render() {
    return (
      <View style={styles.container}>
        <AuthNavigator
          onControllerChanged={(controller) => {
            Logger("Current controller: ", controller);
            this.setState({ currentController: controller });
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 0,
    flex: 1,
  },
});

export default RootAuthController;
