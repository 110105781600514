import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "./NtText";
import colors from "../config/colors";
import NtMaterialIcon from "./NtMaterialIcon";

function NtListEmpty({ visible, message = "No records found." }) {
  if (!visible) {
    return null;
  }
  return (
    <View style={styles.container}>
      <NtMaterialIcon
        name="format-list-bulleted"
        size={20}
        color={colors.lighGray}
      />
      <NtText style={[styles.message, { marginLeft: 6 }]}>{message}</NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 25,
    paddingBottom: 25,
  },
  message: {
    color: colors.lighGray,
    fontSize: 18,
    fontWeight: "600",
    textAlign: "center",
  },
});

export default NtListEmpty;
