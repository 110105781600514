import Logger from "../common/utils/Logger";
import settings from "../config/settings";
import client from "./client";

export const fetchExtensions = (
  domain,
  page,
  pageSize,
  prefix,
  search,
  filter,
  support = false
) => {
  let endpoint = null;

  if (!support) {
    endpoint = `/v2/pbx/domain/${domain}/extension?page=${page}&pagesize=${pageSize}`;

    if (search) {
      endpoint += `&search=${search}`;
    }

    if (prefix) {
      endpoint += `&extension_prefix=${prefix}`;
    }

    if (filter?.extension) {
      endpoint += `&extension=${filter?.extension}`;
    }
    if (filter?.callerIdName) {
      endpoint += `&effective_caller_id_name=${filter?.callerIdName}`;
    }
    if (filter?.callerIdNumber) {
      endpoint += `&effective_caller_id_number=${filter?.callerIdNumber}`;
    }
    if (filter?.outboundCallerIdName) {
      endpoint += `&outbound_caller_id_name=${filter?.outboundCallerIdName}`;
    }
    if (filter?.outboundCallerIdName) {
      endpoint += `&outbound_caller_id_number=${filter?.outboundCallerIdName}`;
    }
    if (filter?.description) {
      endpoint += `&description=${filter?.description}`;
    }

    //type
    if (filter?.type == "analog") {
      endpoint += "&isgateway=1";
    }
    if (filter?.type == "ip") {
      endpoint += "&isgateway=0";
    }

    //subtype
    if (filter?.configured) {
      endpoint += "&configured=1";
    } else if (filter?.unConfigured) {
      endpoint += "&configured=0";
    }

    if (filter?.temporary) {
      endpoint += "&temporary=1";
    } else {
      endpoint += "&temporary=0";
    }

    //other
    if (filter?.gateway) {
      endpoint += `&gateway=${filter?.gateway}`;
    }

    if (filter?.port) {
      endpoint += `&gateway=${filter?.port}`;
    }

    //sort
    if (filter?.orderBy) {
      endpoint += `&orderby=insert_date&order=${filter?.orderBy}`;
    } else {
      endpoint += `&orderby=insert_date&order=DESC`;
    }
  } else {
    endpoint = `/v1/extension/domain/${domain}?page=${page}&pagesize=${pageSize}`;

    if (prefix) {
      endpoint += `&extensionPrefix=${prefix}`;
    }
  }

  Logger("Final endpoint for requesting extensions: ", endpoint);

  return client.get(endpoint);
};

export const fetchExtension = (domain, extension) => {
  let url = `/v2/pbx/domain/${domain}/extension/${extension}`;

  return client.get(url);
};

export const updateExtension = (domain, extension, params) => {
  let endpoint = `/v2/pbx/domain/${domain}/extension/${extension}`;

  let body = {
    effective_caller_id_name: params?.effective_caller_id_name,
    outbound_caller_id_name: params?.outbound_caller_id_name,
    outbound_caller_id_number: params?.outbound_caller_id_number,
    toll_allow: params?.toll_allow,
    description: params?.description,
  };

  return client.patch(endpoint, body);
};

export const fetchExtensionByNumber = (extension, domain) => {
  return client.get(`/v1/extension/domain/${domain}/${extension}`);
};

export const createExtension = async (
  domain,
  extension,
  password,
  phone = "",
  name = "",
  description = ""
) => {
  return new Promise(async (resolve, reject) => {
    //body
    let extensionBody = {
      extension: extension,
      password: password,
      caller_id_name: name,
      description: description,
      mobile_prefix: settings.accountPrefixApp,
      physical_prefix: settings.accountPrefixPhy,
    };

    if (phone) {
      if (phone.startsWith("1")) {
        extensionBody.tn = phone.substring(1);
      } else {
        extensionBody.tn = phone;
      }
    }

    //Will create the extension...
    const extensionResponse = await client.post(
      `/v1/extension/domain/${domain}/createfull`,
      extensionBody
    );

    if (!extensionResponse.ok) {
      Logger("Oops... Unable to create the extension", extensionResponse);
    }

    resolve(extensionResponse);
  });
};
