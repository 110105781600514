import React, { useState, useContext } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import { createExtension } from "../../api/extension";
import { parseError } from "../../api/client";
import NtModalError from "../../components/NtModalError";

function NtExtensionNewForm({ onCancel, onCreate }) {
  const [phone, setPhone] = useState("");
  const [extension, setExtension] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const context = useContext(UserContext);

  const isRequiredFields = () => {
    return name && extension && password && description;
  };

  const handleCreate = async () => {
    if (!isRequiredFields()) {
      return;
    }

    setLoading(true);

    setError(null);
    const response = await createExtension(
      context.user?.environment?.domain,
      extension,
      password,
      phone,
      name,
      description
    );

    if (response.ok) {
      setName("");
      setExtension("");
      setPassword("");
      setDescription("");
      setPhone("");
      if (onCreate) {
        onCreate();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Add new Extension</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Use this to configure your SIP extensions.
      </NtText>

      <NtModalInputField
        containerStyle={{ marginTop: 25 }}
        titleStyle={{ fontWeight: "400" }}
        title={"Phone"}
        placeholder={"Phone"}
        value={phone}
        setValue={(text) => setPhone(text)}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Name *"}
        placeholder={"Name"}
        value={name}
        setValue={(text) => setName(text)}
      />

      <View
        style={{ flexDirection: "row", alignItems: "center", marginTop: 15 }}
      >
        <NtModalInputField
          containerStyle={{ flex: 1 }}
          title={"Extension *"}
          placeholder={"Extension"}
          value={extension}
          setValue={(text) => setExtension(text)}
        />

        <NtModalInputField
          containerStyle={{ flex: 1, marginLeft: 10 }}
          title={"Password *"}
          placeholder={"Password"}
          value={password}
          setValue={(text) => setPassword(text)}
        />
      </View>

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Description *"}
        placeholder={"Description"}
        value={description}
        setValue={(text) => setDescription(text)}
      />

      <NtModalError error={error} containerStyle={{ marginTop: 6 }} />

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 25,
        }}
      >
        {loading && (
          <NtActivityIndicator
            color={colors.blue}
            size="small"
            containerStyle={{ marginRight: 25 }}
          />
        )}
        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
        <NtModalActiveButton
          containerStyle={{ marginLeft: 15 }}
          title={"create"}
          onPress={() => {
            if (isRequiredFields()) {
              handleCreate();
            }
          }}
          enabled={isRequiredFields()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  cancelButton: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.darkest,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
  addButton: {
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: colors.lighGray,
  },
  inputTitle: {
    fontSize: 14,
    fontWeight: "500",
    color: colors.darker,
  },
  input: {
    paddingLeft: 10,
    paddingRight: 10,
    height: 45,
    backgroundColor: colors.lighestGray,
    borderRadius: 8,
  },
});

export default NtExtensionNewForm;
