const extensiontypesdata = [
  {
    id: "analog",
    title: "Analog",
  },
  {
    id: "ip",
    title: "IP",
  },
];

export default extensiontypesdata;
