import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "./NtText";
import colors from "../config/colors";

function NtListRecordsTitle({
  containerStyle,
  title = "Records Found",
  count,
}) {
  return (
    <View style={[styles.container, containerStyle]}>
      <NtText style={styles.title}>
        {title} {count}
      </NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    color: colors.lighGray,
    fontWeight: "800",
    fontSize: 15,
  },
});

export default NtListRecordsTitle;
