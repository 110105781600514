import React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import featurecodedata from "../data/featurecodedata";
import featurecodecsvcolumndata from "../data/featurecodecsvcolumndata";
import BaseController from "../base/BaseController";
import NtListEmpty from "../../components/NtListEmpty";
import NtListHeader from "../../components/NtListHeader";
import Logger from "../utils/Logger";
import NtListRecordsTitle from "../../components/NtListRecordsTitle";
import { asyncStorageSetData } from "../utils/ControllerUtils";
import NtListRow from "../../components/NtListRow";
import colors from "../../config/colors";
import NtText from "../../components/NtText";

class FeatureCodeController extends BaseController {
  state = {
    ...super.state,
    loading: false,
    data: [],
    searchText: "",
  };

  column_location = "@featurecode_column_storage";

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.handleDefaultColumnsFetch(
      this.column_location,
      featurecodecsvcolumndata.filter((each) => each.default == true)
    );
    this.handleDataFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
  };

  handleDataFetch = async () => {
    Logger("Fetching Feature Code history data");

    this.setState({ data: featurecodedata });
  };

  handleExportFetch = async () => {
    Logger("Fetching CDR history export data");

    return featurecodedata;
  };

  handleExportDataParce = (item, column) => {
    if (column == "details") {
      return `"${item[column]}"`;
    }
    return item[column];
  };

  handleSearch = (text) => {
    this.state.searchText = text;
    this.setState({ searchText: this.state.searchText });
    if (text) {
      const found = featurecodedata.filter(
        (each) =>
          each.code?.toLowerCase().includes(text.toLowerCase()) ||
          each.name?.toLowerCase().includes(text.toLowerCase()) ||
          each.details?.toLowerCase().includes(text.toLowerCase())
      );
      this.setState({ data: found });
    } else {
      this.page = 1;
      this.handleDataFetch();
    }
  };

  renderRow = (item, index) => {
    return (
      <NtListRow
        key={item.code + index}
        item={item}
        columns={this.state.columns}
        renderCustom={(item, column) => {
          if (column == "code") {
            return (
              <View
                style={{
                  backgroundColor: colors.lighestGray,
                  paddingLeft: 6,
                  paddingRight: 6,
                  paddingTop: 3,
                  paddingBottom: 3,
                  borderRadius: 20,
                }}
              >
                <NtText
                  style={{
                    color: colors.lighGray,
                    fontWeight: "700",
                    fontSize: 13,
                  }}
                >
                  {item[column]}
                </NtText>
              </View>
            );
          }
        }}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Feature Codes"}
        showFooter={true}
        showBackButton={true}
        searchText={this.state.searchText}
        setSearchText={(text) => {
          this.handleSearch(text);
        }}
      >
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 15 }}
        >
          <NtListRecordsTitle count={this.state.data?.length} />
        </View>

        <View
          style={{
            marginTop: 25,
            flex: 1,
          }}
        >
          <NtListHeader
            visible={this.state.data?.length > 0}
            columns={featurecodecsvcolumndata}
            selectedColumns={this.state.columns}
            onColumnUpdate={(updated) => {
              asyncStorageSetData(this.column_location, updated);
              this.setState({ columns: updated });
            }}
            exportFileName={"feature-code"}
            exportDataParser={this.handleExportDataParce}
            exportFetchDataAsync={this.handleExportFetch}
          />
          <NtListEmpty visible={this.state.data?.length == 0} />
          {this.state.data?.map((each, index) => this.renderRow(each, index))}
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default FeatureCodeController;
