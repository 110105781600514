// import React, { useState } from "react";
// import { View, StyleSheet, Modal } from "react-native";
// import NtTouchableEffect from "./NtTouchableEffect";

// const NtModal = React.forwardRef(({ children, containerStyle }, ref) => {
//   const [visible, setVisible] = useState(false);

//   React.useImperativeHandle(ref, () => ({
//     show: () => {
//       setVisible(true);
//     },
//     dismiss: () => {
//       setVisible(false);
//     },
//   }));

//   return (
//     <View style={styles.container}>
//       <Modal
//         style={{}}
//         animationType="fade"
//         transparent={true}
//         visible={visible}
//         onRequestClose={() => {}}
//       >
//         <View
//           style={{
//             flex: 1,
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <NtTouchableEffect
//             activeOpacity={1}
//             style={{
//               flex: 1,
//               width: "100%",
//               height: "100%",
//               position: "absolute",
//               backgroundColor: "rgba(0, 0, 0, 0.5)",
//             }}
//             onPress={() => {
//               setVisible(false);
//             }}
//           ></NtTouchableEffect>

//           <View style={[styles.modalView, containerStyle]}>{children}</View>
//         </View>
//       </Modal>
//     </View>
//   );
// });

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//   },
//   modalView: {
//     shadowColor: "black",
//     shadowOffset: { width: 0, height: 1 },
//     shadowRadius: 4,
//     shadowOpacity: 0.26,
//     elevation: 1,
//     backgroundColor: "white",
//     padding: 10,
//     borderRadius: 10,
//     maxHeight: "90%",
//   },
// });

// export default NtModal;

import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Modal,
  Pressable,
  TouchableWithoutFeedback,
} from "react-native";
import NtText from "./NtText";
import NtTouchableEffect from "./NtTouchableEffect";
import Logger from "../common/utils/Logger";

const NtModal = React.forwardRef(({ children, containerStyle }, ref) => {
  // function NtModal({ renderBody }) {
  const [visible, setVisible] = useState(false);

  React.useImperativeHandle(ref, () => ({
    show: () => {
      setVisible(true);
    },
    dismiss: () => {
      setVisible(false);
    },
  }));

  return (
    <View style={styles.container}>
      <Modal
        style={{}}
        animationType="fade"
        transparent={true}
        visible={visible}
        onRequestClose={() => {}}
      >
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <NtTouchableEffect
            activeOpacity={1}
            style={{
              flex: 1,
              width: "100%",
              height: "100%",
              position: "absolute",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
            onPress={() => {
              setVisible(false);
            }}
          ></NtTouchableEffect>

          <View style={[styles.modalView, containerStyle]}>{children}</View>
        </View>
      </Modal>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {},

  modalView: {
    shadowColor: "black",
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 4,
    shadowOpacity: 0.26,
    elevation: 1,
    backgroundColor: "white",
    padding: 10,
    borderRadius: 10,
    maxHeight: "90%",
  },
});

export default NtModal;
