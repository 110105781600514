import React from "react";
import { View, StyleSheet } from "react-native";
import BaseController from "../../common/base/BaseController";
import { asyncStorageGetData } from "../../common/utils/ControllerUtils";

class BasePinController extends BaseController {
  state = {
    ...super.state,
  };
}

const styles = StyleSheet.create({
  container: {},
});

export default BasePinController;
