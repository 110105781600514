const pincsvcolumndata = [
  {
    id: "id",
    title: "Pin",
    default: true,
  },
  {
    id: "name",
    title: "Name",
    default: true,
  },
  {
    id: "tariff",
    title: "Type",
    default: true,
  },
  {
    id: "credit",
    title: "Credit",
    default: true,
  },
  {
    id: "creationdate",
    title: "Creation Date",
  },
  {
    id: "expirationdate",
    title: "Expiration Date",
    default: true,
  },
  {
    id: "lastuse",
    title: "Last Use",
  },
  {
    id: "initialbalance",
    title: "Initial Credit",
  },

  {
    id: "status",
    title: "Status",
  },
];
export default pincsvcolumndata;
