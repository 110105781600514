import Papa from "papaparse";
import Logger from "../../common/utils/Logger";
import { sleep } from "../../common/utils/ControllerUtils";
import { createPin } from "../../api/billing";

export const handlePinUploadCreate = async (file, environment) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        Logger("Done reading the .csv... will start processing now..");

        const success = [];
        const failure = [];

        results.data.map(async (each, index) => {
          const response = await createPin(
            environment?.domain,
            each.pin,
            each.name,
            each.expirationdate,
            each.type,
            each.credit
          );

          Logger("Pin upload POST", response);

          //slowing down the request
          await sleep(500);

          if (response.ok) {
            success.push(each);
          } else {
            failure.push({
              request: each,
              status: response.status,
              error: response.data,
            });
          }

          if (success.length + failure.length == results.data.length) {
            resolve({
              ok: true,
              success: success,
              failure: failure,
              total: results.data.length,
            });
          }
        });
      },
    });
  });
};

export const handlePinUploadReset = async (file, environment) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        Logger("Done reading the .csv... will start processing now..");

        let data = [];
        results.data.map(async (each, index) => {
          data.push(each.pin);
        });

        resolve({ data: data });
      },
    });
  });
};

export const handlePinUploadFailure = async (failure) => {
  return new Promise((resolve, reject) => {
    resolve({ ok: true, data: JSON.stringify(failure, null, 2) });
  });
};
