import React from "react";
import { View, StyleSheet } from "react-native";
import BaseController from "../base/BaseController";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import NtText from "../../components/NtText";
import settings from "../../config/settings";
import Logger from "../utils/Logger";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import colors from "../../config/colors";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import routes from "../../navigation/routes";

class UpgradeController extends BaseController {
  state = {};

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
  };

  data = (module) => {
    if (settings.moduleOmniChannel) {
      return {
        icon: "gamepad-circle-outline",
        message:
          "To add the Omnichannel Marketing Outreach module, please contact your administrator",
      };
    } else if (settings.moduleCallingCards) {
      return {
        icon: "dialpad",
        message:
          "To add the Call Accounting (pin dialing) module, please contact your administrator.",
      };
    } else if (settings.modulePbx) {
      return {
        icon: "deskphone",
        message: "To add the PBX module, please contact your administrator.",
      };
    }
  };

  renderDismissButton = (containerStyle) => {
    return (
      <NtTouchableEffect
        style={[{}, containerStyle]}
        onPress={() => {
          this.props.navigation.navigate(routes.dashboard);
        }}
      >
        <NtText
          style={{
            fontStyle: "italic",
            color: colors.blue,
            fontSize: 14,
            fontWeight: "600",
          }}
        >
          Dismiss
        </NtText>
      </NtTouchableEffect>
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Upgrade"}
        showFooter={true}
        showBackButton={false}
      >
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              maxWidth: "80%",
            }}
          >
            <NtMaterialIcon
              name={this.data(this.props?.route?.params?.module)?.icon}
              color={colors.dark}
              size={30}
            />
            <NtText style={[styles.text, { marginLeft: 10 }]}>
              {this.data(this.props?.route?.params?.module)?.message}
            </NtText>
          </View>
          {this.renderDismissButton({ marginTop: 25 })}
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  text: {
    fontSize: 18,
    fontWeight: "600",
    color: colors.dark,
  },
});

export default UpgradeController;
