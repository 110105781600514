import React, { useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtDescriptionHightlight from "./NtDescriptionHightlight";
import NtListRow from "../../components/NtListRow";
import { parseExtension } from "../utils/ExtensionUtils";
import NtModal from "../../components/NtModal";
import NtExtensionUpdateForm from "./NtExtensionUpdateForm";

function NtExtensionItem({ item, columns, onGatewayPress, onUpdate }) {
  const updateModalRef = useRef();

  const renderUpdateForm = () => {
    return (
      <NtModal ref={updateModalRef}>
        <NtExtensionUpdateForm
          item={item}
          onCancel={() => {
            updateModalRef.current?.dismiss();
          }}
          onUpdate={() => {
            updateModalRef.current?.dismiss();
            if (onUpdate) {
              onUpdate();
            }
          }}
          onReset={() => {
            updateModalRef.current?.dismiss();
            if (onUpdate) {
              onUpdate();
            }
          }}
        />
      </NtModal>
    );
  };

  return (
    <View style={styles.container}>
      <NtListRow
        item={item}
        columns={columns}
        renderCustom={(item, column) => {
          if (column == "description") {
            return (
              <NtDescriptionHightlight
                description={item[column]}
                onPress={() => {
                  if (onGatewayPress) {
                    onGatewayPress(item[column]?.split(",")[0]);
                  }
                }}
              />
            );
          } else if (column == "extension") {
            return parseExtension(item[column]);
          } else if (column == "toll_allow") {
            return item[column] && item[column] == "t" ? "True" : "False";
          }
        }}
        onActionPress={() => {
          updateModalRef.current?.show();
        }}
      />
      {renderUpdateForm()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtExtensionItem;
