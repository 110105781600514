const destinationactiondata = [
  {
    id: "extension",
    icon: "phone",
    title: "Extension",
  },
  {
    id: "ringgroup",
    icon: "account-group",
    title: "Ring Group",
  },
  {
    id: "voicemail",
    icon: "voicemail",
    title: "Voicemail",
  },
];

export default destinationactiondata;
