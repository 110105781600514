const appaccesscsvcolumndata = [
  {
    id: "id",
    title: "ID",
  },
  {
    id: "extension",
    title: "Extension",
    default: true,
  },
  {
    id: "preference",
    title: "Preference",
    default: true,
  },
  {
    id: "email",
    title: "Email",
    default: true,
  },
  {
    id: "status",
    title: "Status",
  },
  {
    id: "creationdate",
    title: "Date",
  },
];
export default appaccesscsvcolumndata;
