import React, { useContext, useState } from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";
import UserContext from "../../context/UserContext";
import { useMobile } from "../../hooks/useMobile";
import { useNavigation } from "@react-navigation/native";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import routes from "../../navigation/routes";
import NtEnvironmentDropdown from "./NtEnvironmentDropdown";
import Logger from "../../common/utils/Logger";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtText from "../../components/NtText";

function NtNavigationBar({ sidebarCollapsed = false, onSidebarToggle }) {
  const context = useContext(UserContext);
  const nav = useNavigation();
  const isMobile = useMobile();

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <NtTouchableEffect
          style={{ paddingLeft: 10, paddingRight: 10 }}
          onPress={onSidebarToggle}
        >
          <NtMaterialIcon
            name={
              sidebarCollapsed ? "chevron-double-right" : "chevron-double-left"
            }
            color={colors.blue}
            size={26}
          />
        </NtTouchableEffect>

        <NtTouchableEffect
          onPress={() => {
            nav.navigate(routes.dashboard);
          }}
        >
          <NtText style={styles.title}>
            {context.organization?.name || "NTMARITIME"}
          </NtText>
        </NtTouchableEffect>
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {!context.user?.environmentAutoloaded && (
          <NtEnvironmentDropdown containerStyle={{ marginRight: 30 }} />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    backgroundColor: "white",
    flexDirection: "row",
    shadowColor: "black",
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 2,
    shadowOpacity: 0.26,
    elevation: 1,
    marginBottom: 2,
    paddingTop: 6,
    paddingBottom: 6,
    height: 50,
  },
  title: {
    fontSize: 24,
    fontWeight: "900",
    color: colors.blue,
  },
});

export default NtNavigationBar;
