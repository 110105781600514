const cdrcsvcolumndata = [
  {
    id: "id",
    title: "Id",
  },
  {
    id: "callid",
    title: "Call ID",
  },
  {
    id: "pin",
    title: "Pin",
    default: true,
  },
  {
    id: "name",
    title: "Pin Name",
  },
  {
    id: "status",
    title: "Pin Status",
  },
  {
    id: "tariff",
    title: "Pin Type",
    default: true,
  },
  {
    id: "src",
    title: "From",
    default: true,
  },
  {
    id: "destination",
    title: "To",
    default: true,
  },
  {
    id: "destination_name",
    title: "Destination Name",
  },
  {
    id: "starttime",
    title: "Start Time",
    default: true,
  },
  {
    id: "stoptime",
    title: "End Time",
    default: true,
  },
  {
    id: "sessiontime",
    title: "Call Minutes",
  },
  {
    id: "billed_sessiontime",
    title: "Billed Minutes",
    default: true,
  },
  {
    id: "blockprice",
    title: "Price per Minute",
  },
  {
    id: "sessionprice",
    title: "Call Price",
    default: true,
  },
  {
    id: "answered",
    title: "Answered",
  },
  {
    id: "terminatecauseid",
    title: "Termination Cause",
    default: true,
  },
];
export default cdrcsvcolumndata;
