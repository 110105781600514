const ringgroupstrategytypesdata = [
  {
    id: "enterprise",
    title: "Enterprise",
  },
  {
    id: "simultaneous",
    title: "Simultaneous",
  },
  {
    id: "sequence",
    title: "Sequence",
  },
  {
    id: "rollover",
    title: "Rollover",
  },
  {
    id: "random",
    title: "Random",
  },
];

export default ringgroupstrategytypesdata;
