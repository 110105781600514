import React, { useContext } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtDevider from "../../components/NtDevider";
import colors from "../../config/colors";
import environmentsdata from "../data/environmentsdata";
import UserContext from "../../context/UserContext";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtEnvironmentPicker({ onPress }) {
  const context = useContext(UserContext);

  const renderRow = (item, index) => {
    return (
      <NtTouchableEffect
        key={index}
        onPress={() => {
          context.updateUser({ ...context.user, environment: item });
          if (onPress) {
            onPress(item);
          }
        }}
      >
        <View
          style={{
            paddingTop: 15,
            paddingBottom: 15,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 1 }}>
            <NtText style={styles.rowTitle}>{item.name}</NtText>
            <NtText style={styles.rowSubtitle}>{item.code}</NtText>
          </View>

          {item.code == context?.user?.environment?.code && (
            <NtMaterialIcon name="check" color={colors.darkest} />
          )}
        </View>

        <NtDevider />
      </NtTouchableEffect>
    );
  };

  return (
    <View style={styles.container}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <NtText style={[styles.title, { marginRight: 10 }]}>
            Select Environment
          </NtText>
        </View>

        {environmentsdata?.map((each, index) => renderRow(each, index))}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: 300,
    padding: 15,
    maxHeight: 450,
  },
  title: {
    color: colors.darkest,
    fontSize: 17,
    fontWeight: "700",
  },
  rowTitle: {
    color: colors.darkest,
    fontSize: 14,
    fontWeight: "600",
  },
  rowSubtitle: {
    color: colors.darkest,
    fontSize: 12,
    fontWeight: "400",
  },
  error: {
    color: "red",
    fontSize: 12,
    fontWeight: "400",
  },
});

export default NtEnvironmentPicker;
