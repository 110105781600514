import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { linking } from "./src/navigation/routes";
import RootAppController from "./src/root/controllers/RootAppController";
import Toast from "react-native-toast-message";
import Logger from "./src/common/utils/Logger";
import {
  asyncStorageGetData,
  asyncStorageSetData,
} from "./src/common/utils/ControllerUtils";
import { UserProvider } from "./src/context/UserContext";
import settings from "./src/config/settings";
import client from "./src/api/client";
import RootAuthController from "./src/root/controllers/RootAuthController";
import MessagingClient from "./src/maritime/utils/MessagingClient";
import { fetchOrganization } from "./src/api/organization";
import NtText from "./src/components/NtText";
import { fetchRemoteEnvironment } from "./src/api/environment";

export const navigationRef = React.createRef();

export default function App() {
  const [organization, setOrganization] = useState();
  const [user, setUser] = useState();
  const [isReady, setIsReady] = useState(false);
  const DEFAULT_USER_STORE_LOCATION = "@maritime_user_location_v4";

  const updateEnvironment = (user) => {
    if (user?.environment) {
      Logger(`Will update the environment API now ${user?.environment?.code}`);
      global.apiAuth = user?.environment?.apiAuth;
      client.setBaseURL(user?.environment.apiUrl);
    }
  };

  const updateStompConnection = (user) => {
    if (user.username && user.password && user?.environment?.stompUrl) {
      Logger("Will initialize the messaging client");
      global.messagingService = user?.environment?.messagingService;
      const client = new MessagingClient();
      client.init(user);
      client.connect();
    }
  };

  const handleOrganizationFetch = async (user) => {
    if (user?.environment) {
      const response = await fetchOrganization(user?.environment?.code);
      Logger("Organization fetched", response);
      if (response.ok) {
        setOrganization(response.data);
      } else {
        setOrganization(null);
      }
    }
  };

  const loadUser = async () => {
    var user = await asyncStorageGetData(DEFAULT_USER_STORE_LOCATION);

    user = JSON.parse(user);

    //Will never initialize the user to null,
    if (user === null) {
      user = {};
    }

    // First.... will try to pick up a remote environment.... in case there is one
    const response = await fetchRemoteEnvironment();
    Logger("Finished fetching the remote environment", response);
    if (response.ok && response.data?.apiUrl && response.data?.domain) {
      Logger("Found a good remote environment.... will accomodate the user");
      user.environment = response.data;
      user.environmentAutoloaded = true;
    }

    updateEnvironment(user);
    updateStompConnection(user);
    setUser(user);
    setIsReady(true);
    handleOrganizationFetch(user);
    Logger("Current user loaded: ", user);
  };

  const updateUser = (user) => {
    asyncStorageSetData(DEFAULT_USER_STORE_LOCATION, user);

    updateEnvironment(user);
    updateStompConnection(user);
    setUser(user);
    handleOrganizationFetch(user);
  };

  if (!isReady) {
    //will fix this in a bit....
    loadUser();
    return null;
  }

  return (
    <View style={styles.container}>
      <UserProvider
        value={{
          organization,
          user,
          updateUser,
        }}
      >
        <NavigationContainer linking={linking} ref={navigationRef}>
          {user && user.authenticated ? (
            <RootAppController />
          ) : (
            <RootAuthController />
          )}
          {/* <RootAppController /> */}
          <Toast />
        </NavigationContainer>
      </UserProvider>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
