import React from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";

function NtSection({ containerStyle, title, children, loading = false }) {
  return (
    <View style={[styles.container, containerStyle]}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 4,
          marginBottom: 8,
        }}
      >
        <NtText style={[styles.title]}>{title}</NtText>
        {loading && (
          <NtActivityIndicator
            containerStyle={{ marginLeft: 10 }}
            size="small"
            color={colors.blue}
          />
        )}
      </View>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 18,
    color: colors.blue,
    fontWeight: "600",
  },
});

export default NtSection;
