import React from "react";
import { View, StyleSheet } from "react-native";
import NtTouchableEffect from "./NtTouchableEffect";
import NtText from "./NtText";
import colors from "../config/colors";
import Logger from "../common/utils/Logger";

function NtTabItem({
  containerStyle,
  textStyle,
  item,
  onPress,
  selected = false,
}) {
  return (
    <NtTouchableEffect
      style={[
        styles.container,
        selected ? { backgroundColor: colors.almostBlack } : {},
        containerStyle,
      ]}
      onPress={onPress}
    >
      <NtText
        style={[
          { fontSize: 14, fontWeight: "600", color: colors.almostBlack },
          selected ? { color: "white" } : {},
          textStyle,
        ]}
      >
        {item?.title}
      </NtText>
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 20,
    borderWidth: 1,
    marginRight: 10,
    borderColor: colors.almostBlack,
  },
});

export default NtTabItem;
