import React from "react";
import { View, StyleSheet, ScrollView, Image } from "react-native";
import NtText from "../../components/NtText";
import NtFooterBar from "../../root/components/NtFooterBar";
import NtHeader from "../../marketing/components/NtHeader";
import colors from "../../config/colors";
import NtMaterialIcon from "../../components/NtMaterialIcon";

class GovController extends React.Component {
  state = {};

  renderBox = (title, value, containerStyle) => {
    return (
      <View
        style={[
          {
            flex: 1,
            backgroundColor: colors.lighestGray,
            padding: 10,
            borderRadius: 6,
          },
          containerStyle,
        ]}
      >
        <NtText
          style={{ fontSize: 18, fontWeight: "600", color: colors.almostBlack }}
        >
          {value}
        </NtText>
        <NtText
          style={{
            marginTop: 10,
            fontSize: 12,
            fontWeight: "600",
            color: colors.lighGray,
          }}
        >
          {title}
        </NtText>
      </View>
    );
  };

  renderCompetencies = (title, containerStyle) => {
    return (
      <View
        style={{
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 4,
          paddingBottom: 4,
          backgroundColor: "white",
          borderRadius: 20,
          marginRight: 10,
          marginBottom: 10,
        }}
      >
        <NtText
          style={{ fontSize: 14, fontWeight: "500", color: colors.almostBlack }}
        >
          {title}
        </NtText>
      </View>
    );
  };

  renderNaics = (icon, title, containerStyle) => {
    return (
      <View
        style={[{ flexDirection: "row", alignItems: "center" }, containerStyle]}
      >
        <NtMaterialIcon color={colors.almostBlack} name={icon} />
        <NtText style={{ fontSize: 16, fontWeight: "500", marginLeft: 10 }}>
          {title}
        </NtText>
      </View>
    );
  };

  renderPerformance = (title, description, containerStyle) => {
    return (
      <View style={[{}, containerStyle]}>
        <NtText
          style={{ fontSize: 16, fontWeight: "600", color: colors.almostBlack }}
        >
          {title}
        </NtText>
        <NtText
          style={{
            fontSize: 13,
            fontWeight: "600",
            color: colors.lighGray,
            fontStyle: "italic",
          }}
        >
          {description}
        </NtText>
      </View>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <NtHeader
            containerStyle={{ position: "relative" }}
            hoverEnabled={false}
          />
          {/* main view */}
          <View style={{ marginTop: 35 }}>
            <View
              style={{
                flexDirection: "row",
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingLeft: 25,
                  paddingRight: 25,
                  paddingBottom: 25,
                  borderRadius: 20,
                  flex: 1,
                }}
              >
                <View style={{ flex: 1, justifyContent: "center" }}>
                  <NtText
                    style={{
                      fontSize: 12,
                      fontWeight: "600",
                      fontStyle: "italic",
                      color: colors.blue,
                    }}
                  >
                    NTMaritime
                  </NtText>
                  <NtText
                    style={{
                      fontSize: 40,
                      fontWeight: "600",
                      color: colors.almostBlack,
                    }}
                  >
                    Company Overview
                  </NtText>
                </View>

                <NtText
                  style={{
                    lineHeight: 20,
                    fontSize: 13,
                    fontWeight: "500",
                  }}
                >
                  <NtText style={{ fontWeight: "800", fontSize: 14 }}>
                    NT Connect Holdings, Inc.
                  </NtText>{" "}
                  (NT Connect) established in 2018, is a leading provider of
                  communication intelligence and IT solutions, specializing in
                  building and managing mission-critical communication networks.
                  We offer advanced services including LEO satellite
                  connectivity, location-based services, contact tracing, access
                  control systems, and cutting-edge telehealth technologies. Our
                  clients include the U.S. Navy, Canadian Coast Guard, Cunard
                  Line, Disney Cruise Line, Holland America, Norwegian Cruise
                  Line, and P&O Cruises.
                </NtText>
              </View>

              <View
                style={{
                  backgroundColor: "white",
                  borderRadius: 20,
                  flex: 0.8,
                  marginLeft: 15,
                }}
              >
                <View
                  style={{
                    backgroundColor: "#2d9cff",
                    borderRadius: 20,
                    alignItems: "center",
                    paddingTop: 60,
                  }}
                >
                  <Image
                    style={[styles.image, {}]}
                    source={require("../../assets/images/marketing-hospitality-features-woman.png")}
                  />
                </View>

                <View style={{ padding: 10 }}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {this.renderBox("UEI", "VXB5ZNM6U189")}
                    {this.renderBox("DUNS", "111241", { marginLeft: 20 })}
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    {this.renderBox("CAGE Code", "9TQL0")}
                    {this.renderBox("SBA", "Small Business", {
                      marginLeft: 20,
                    })}
                  </View>
                </View>
              </View>
            </View>

            {/* core competencies */}
            <View
              style={{
                backgroundColor: "#2d9cff",
                marginTop: 40,
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 40,
              }}
            >
              <NtText
                style={{
                  fontSize: 28,
                  fontWeight: "600",
                  color: "white",
                  marginTop: 20,
                }}
              >
                Core Competencies
              </NtText>
              <NtText style={{ marginTop: 20 }}>
                {this.renderCompetencies("Interconnected VoIP")}
                {this.renderCompetencies("Enterprise Grade Deployments")}
                {this.renderCompetencies("TDM to IP HW Deployments")}
                {this.renderCompetencies("API & Middleware Development")}
                {this.renderCompetencies("Mobile Application Development")}
                {this.renderCompetencies(
                  "SMS over IP, RCS, XMPP, AMQP, MQTT, WebRTC"
                )}
                {this.renderCompetencies("POTS to SIP Modernization")}
                {this.renderCompetencies("Unified Communications (UCaaS)")}
                {this.renderCompetencies("On-Premises and Cloud PBX")}
                {this.renderCompetencies(
                  "Enterprise Grade DevOps & DEVSecOps: CI/CD/IaC"
                )}
                {this.renderCompetencies("Cloud-Native & Hybrid Cloud Support")}
                {this.renderCompetencies(
                  "Apple Push Notifications, Google Firebase Cloud Messaging"
                )}
              </NtText>
              <NtText
                style={{
                  fontSize: 20,
                  fontWeight: "600",
                  color: "white",
                  marginTop: 20,
                }}
              >
                Differentiator
              </NtText>

              <NtText
                style={{
                  fontSize: 13,
                  fontWeight: "500",
                  color: "white",
                  lineHeight: 16,
                  marginTop: 10,
                }}
              >
                <NtText style={{ fontWeight: "900" }}>NT Connect</NtText>{" "}
                delivers secure communication systems for government,
                enterprise, and maritime sectors. Our proven expertise in
                air-gapped maritime environments seamlessly extends to
                land-based projects, providing tailored, user-friendly solutions
                in even the most challenging settings.
              </NtText>

              <NtText
                style={{
                  fontSize: 20,
                  fontWeight: "600",
                  color: "white",
                  marginTop: 20,
                }}
              >
                Why Do Customers Choose Us?
              </NtText>

              <NtText
                style={{
                  fontSize: 13,
                  fontWeight: "500",
                  color: "white",
                  lineHeight: 16,
                  marginTop: 10,
                }}
              >
                <NtMaterialIcon name="hand-pointing-right" color="white" /> NT
                Connect maximizes ROI by leveraging existing equipment, ensuring
                the initial investment is retained, while seamlessly modernizing
                the backend for enhanced performance and scalability. NT
                Connect’s proven Methodology Framework—from discovery to
                support—ensures quality control, clear expectations, and on-time
                delivery. Our secure, customizable solutions serve both maritime
                and land-based environments, consistently delivering
                high-quality results in complex, remote settings. With deep
                expertise and a strong track record, we are a reliable partner
                for mission-critical communication systems.
              </NtText>
            </View>

            <View
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 40,
                paddingBottom: 40,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  padding: 25,
                  borderRadius: 20,
                  flex: 0.6,
                }}
              >
                <NtText
                  style={{
                    fontSize: 18,
                    fontWeight: "600",
                    color: colors.almostBlack,
                  }}
                >
                  NAICS
                </NtText>
                {this.renderNaics("star", "517111", { marginTop: 25 })}
                {this.renderNaics("radiobox-marked", "517111", {
                  marginTop: 10,
                })}
                {this.renderNaics("radiobox-marked", "517111", {
                  marginTop: 10,
                })}
                {this.renderNaics("radiobox-marked", "517111", {
                  marginTop: 10,
                })}
                {this.renderNaics("radiobox-marked", "517111", {
                  marginTop: 10,
                })}
                {this.renderNaics("radiobox-marked", "517111", {
                  marginTop: 10,
                })}
                {this.renderNaics("radiobox-marked", "517111", {
                  marginTop: 10,
                })}
                {this.renderNaics("radiobox-marked", "517111", {
                  marginTop: 10,
                })}
                {this.renderNaics("radiobox-marked", "517111", {
                  marginTop: 10,
                })}

                <NtText
                  style={{
                    fontSize: 18,
                    fontWeight: "600",
                    color: colors.almostBlack,
                    marginTop: 30,
                  }}
                >
                  Certifications
                </NtText>

                {this.renderNaics("certificate", "ISO 27001", {
                  marginTop: 25,
                })}
              </View>

              <View
                style={{
                  backgroundColor: "white",
                  padding: 25,
                  borderRadius: 20,
                  marginLeft: 20,
                  flex: 1,
                }}
              >
                <View style={{ flex: 1, justifyContent: "center" }}>
                  <NtText
                    style={{
                      fontSize: 30,
                      fontWeight: "600",
                      color: colors.almostBlack,
                    }}
                  >
                    Past Performance
                  </NtText>
                </View>

                {this.renderPerformance(
                  "Navy / NAVSUP US Fleet Forces CMD",
                  "(2024-present): Award N7027224P0020 - VoIP Transition",
                  { marginTop: 35 }
                )}
                {this.renderPerformance(
                  "Canadian Coast Guard / Inmarsat",
                  "(2021-present): Aids to Navigation–Lighthouse Communications",
                  { marginTop: 15 }
                )}
                {this.renderPerformance(
                  "Carnival UK",
                  "(2024-present): Crew/Ship Communication",
                  { marginTop: 15 }
                )}
                {this.renderPerformance(
                  "Holland America/Princess Alaska",
                  "(2023-present): Hotel PBX Modernization",
                  { marginTop: 15 }
                )}
                {this.renderPerformance(
                  "Disney Cruise Line",
                  "(2019-present): Onboard Communications",
                  { marginTop: 15 }
                )}
                {this.renderPerformance(
                  "Norwegian Cruise Line Holdings",
                  "(2018-present): Passenger Communications",
                  { marginTop: 15 }
                )}
              </View>
            </View>
          </View>
          <NtFooterBar
            onContactUs={() => {
              setTimeout(() => {
                this.handleFocus();
              }, 300);
            }}
          />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: { flex: 1 },
  image: {
    width: 150,
    height: 150,
    resizeMode: "contain",
  },
});

export default GovController;
