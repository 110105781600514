import { create } from "apisauce";
import settings from "../config/settings";

const clientenvironment = create({
  baseURL: window.location.hostname,
  headers: { Accept: "application/json" },
});

clientenvironment.addAsyncRequestTransform(async (request) => {
  request.headers["Content-Type"] = "application/json";
  request.headers["Accept"] = "application/hal+json";
});

export default clientenvironment;
