import React, { useState, useContext } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import Logger from "../../common/utils/Logger";
import { parseError } from "../../api/client";
import NtTabController from "../../components/NtTabController";
import ringgroupstrategytypesdata from "../data/ringgroupstrategytypesdata";
import {
  createRingGroup,
  createRingGroupDestination,
  updateRingGroup,
} from "../../api/ringgroup";
import NtModalAccountPickerField from "./NtModalAccountPickerField";
import NtRingGroupDestinationsField from "./NtModalRingGroupDestinationsField";
import NtModalError from "../../components/NtModalError";

function NtInboundRouteNewForm({ onCancel, onCreate }) {
  const [extension, setExtension] = useState("");
  const [name, setName] = useState("");
  const [callTimeout, setCalltimeout] = useState("30");
  const [timeoutDestination, setTimeoutDestination] = useState("");
  const [strategy, setStrategy] = useState(ringgroupstrategytypesdata[0]);
  const [destinations, setDestinations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const context = useContext(UserContext);

  const isRequiredFields = () => {
    return extension && name && callTimeout && strategy;
  };

  const handleCreate = async () => {
    setLoading(true);

    setError(null);

    const params = {
      ring_group_extension: extension,
      ring_group_name: name,
      ring_group_call_timeout: callTimeout,
      ring_group_strategy: strategy?.id,
      ring_group_timeout_action: timeoutDestination,
    };

    const response = await createRingGroup(
      context.user?.environment?.domain,
      params
    );

    Logger("Ringgroup Create", response);

    if (response.ok) {
      //will now create all the destinations
      if (destinations && destinations?.length > 0) {
        for (let x = 0; x < destinations.length; x++) {
          await createRingGroupDestination(
            context.user?.environment?.domain,
            response.data?.ring_group_extension,
            destinations[x]
          );
        }
      }

      if (onCreate) {
        onCreate();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  const renderStrategy = (containerStyle) => {
    return (
      <View style={containerStyle}>
        <NtText style={[styles.fieldTitle, { marginLeft: 4 }]}>
          Strategy *
        </NtText>

        <NtTabController
          containerStyle={{ marginTop: 4 }}
          options={ringgroupstrategytypesdata}
          selection={strategy}
          onPress={(item) => {
            setStrategy(item);
          }}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Add new Inbound Route</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Route incoming calls to destinations based on one or more conditions. It
        can send incoming calls to an IVR Menu, Ring Group, Extension, External
        Number, etc.
      </NtText>

      <NtModalInputField
        containerStyle={{ marginTop: 25 }}
        title={"Extension *"}
        placeholder={"Extension"}
        value={extension}
        setValue={(text) => setExtension(text)}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 25 }}
        title={"Name *"}
        placeholder={"Name"}
        value={name}
        setValue={(text) => setName(text)}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Call Timeout *"}
        placeholder={"Call Timeout"}
        value={callTimeout}
        setValue={(text) => setCalltimeout(text)}
      />

      <NtRingGroupDestinationsField
        title={"Destinations"}
        containerStyle={{ marginTop: 15 }}
        destinations={destinations}
        onCreate={(item) => {
          Logger("On Create", item);
        }}
        onDelete={(item) => {
          Logger("On Delete", item);
        }}
        handleCreateRequest={(params) => {
          Logger("Will handle the create ", params);

          if (
            destinations?.find(
              (each) => each.destination_number == params?.destination_number
            )
          ) {
            return { ok: false, data: { error: "Destination already exists" } };
          }

          setDestinations([...destinations, params]);
          return { ok: true };
        }}
        handleDeleteRequest={(params) => {
          Logger("Will handle the delete ", params);

          setDestinations(
            destinations?.filter(
              (each) => each.destination_number != params?.destination_number
            )
          );
          return { ok: true };
        }}
      />

      <NtModalAccountPickerField
        containerStyle={{ marginTop: 15 }}
        title={"Timeout Destination"}
        placeholder={"Timeout Destination"}
        value={timeoutDestination}
        setValue={(text) => setTimeoutDestination(text)}
      />

      {renderStrategy({ marginTop: 15 })}

      <NtModalError error={error} containerStyle={{ marginTop: 6 }} />

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 25,
        }}
      >
        {loading && (
          <NtActivityIndicator
            color={colors.blue}
            size="small"
            containerStyle={{ marginRight: 25 }}
          />
        )}

        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />

        <NtModalActiveButton
          containerStyle={{ marginLeft: 15 }}
          title={"Create"}
          onPress={() => {
            if (isRequiredFields()) {
              handleCreate();
            }
          }}
          enabled={isRequiredFields()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtInboundRouteNewForm;
