import React, { useState, useContext } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import NtTouchableEffect from "../../components/NtTouchableEffect";

import Logger from "../../common/utils/Logger";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import { handlePinUploadReset } from "../utils/PinUploadParser";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import { resetPin } from "../../api/billing";
import NtModalError from "../../components/NtModalError";

function NtBulkResetActionForm({ onCancel, onUploaded }) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadResponse, setUploadResponse] = useState(null);

  const context = useContext(UserContext);

  const handleUpload = async () => {
    setLoading(true);
    let response = await handlePinUploadReset(file, context.user?.environment);

    const data = response.data;
    response = await resetPin(context.user?.environment?.domain, data);
    Logger("Finished reseting uploaded pins: ", response);

    setUploadResponse(response);
    setLoading(false);
  };

  const handleFailureDownload = async () => {
    const fileData = uploadResponse?.data?.rejected?.list;

    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "failure-uploaded-resets.txt";
    link.href = url;
    link.click();
  };

  const handleDone = () => {
    if (onUploaded) {
      onUploaded(uploadResponse);
    }
  };

  const isRequiredFields = () => {
    return file;
  };

  const changeHandler = async (event) => {
    setFile(event.target.files[0]);
    setUploadResponse(null);
  };

  const renderChooseCSV = () => {
    if (file) {
      return null;
    }
    return (
      <NtTouchableEffect
        style={{}}
        onPress={() => {
          document.getElementById("selectFile").click();
        }}
      >
        <NtText style={{ fontSize: 13, fontWeight: "400", color: colors.blue }}>
          Choose CSV file
        </NtText>
      </NtTouchableEffect>
    );
  };

  const renderInfoCSV = () => {
    if (!file) {
      return null;
    }
    return (
      <View>
        <NtText>{file?.name}</NtText>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <NtMaterialIcon name="alert-outline" />
        <NtText style={[styles.title, { marginLeft: 6 }]}>
          Manage Cabin Pins Reset
        </NtText>
      </View>

      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        This will allow to reset a bulk amount. column MUST be named (case
        sensitive):{" "}
        <NtText style={[styles.subtitle, { fontWeight: "bold" }]}>pin</NtText>
      </NtText>

      <View
        style={{
          borderRadius: 4,
          borderWidth: 1,
          borderColor: colors.lightest,
          backgroundColor: colors.lighestGray,
          justifyContent: "center",
          padding: 10,
          marginTop: 30,
        }}
      >
        {renderChooseCSV()}
        {renderInfoCSV()}
      </View>

      {/* In the event that response is 409, then I show the error message only because the complete upload failed*/}
      {uploadResponse && uploadResponse?.status == 409 && (
        <NtModalError
          error={uploadResponse?.data?.error}
          containerStyle={{ marginTop: 6 }}
        />
      )}

      {/* In the event that response is 200, then I show the list response which includes total, success, and errors */}
      {uploadResponse && uploadResponse?.status != 409 && (
        <View
          style={{
            marginTop: 6,
          }}
        >
          <NtText style={[styles.uploadResponseText]}>
            Total:{" "}
            {uploadResponse?.data?.accepted?.obj?.length ||
              0 + uploadResponse?.data?.rejected?.obj?.length ||
              0}
          </NtText>
          <NtText style={[styles.uploadResponseText, { marginTop: 4 }]}>
            Success: {uploadResponse?.data?.accepted?.obj?.length || 0}
          </NtText>
          <NtTouchableEffect
            style={{ marginTop: 4 }}
            onPress={() => {
              if (uploadResponse?.data?.rejected?.obj?.length > 0) {
                handleFailureDownload();
              }
            }}
          >
            <NtText style={[styles.uploadResponseText, { color: "red" }]}>
              Error: {uploadResponse?.data?.rejected?.obj?.length || 0}
            </NtText>
          </NtTouchableEffect>
        </View>
      )}

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 25,
        }}
      >
        {loading && (
          <NtActivityIndicator
            color={colors.blue}
            size="small"
            containerStyle={{ marginRight: 25 }}
          />
        )}

        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
        <NtModalActiveButton
          containerStyle={{ marginLeft: 15 }}
          title={uploadResponse ? "Done" : "Reset Cabins"}
          onPress={
            isRequiredFields()
              ? () => {
                  if (uploadResponse) {
                    handleDone();
                  } else {
                    handleUpload();
                  }
                }
              : null
          }
          enabled={isRequiredFields()}
        />
      </View>

      <input
        id="selectFile"
        type="file"
        name="file"
        accept=".csv"
        style={{ display: "none" }}
        onChange={changeHandler}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  uploadResponseText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.darkest,
  },
});

export default NtBulkResetActionForm;
