import React from "react";
import { View, StyleSheet } from "react-native";
import NtTouchableEffect from "./NtTouchableEffect";
import NtMaterialIcon from "./NtMaterialIcon";
import colors from "../config/colors";
import NtActivityIndicator from "./NtActivityIndicator";

function NtListActionButton({
  onPress,
  icon = "pencil",
  color = colors.blue,
  loading = false,
}) {
  return (
    <NtTouchableEffect style={styles.container} onPress={onPress}>
      {loading && <NtActivityIndicator color={color} size="small" />}
      {!loading && <NtMaterialIcon name={icon} color={color} />}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: colors.lighestGray,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default NtListActionButton;
