import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtPinItem({
  containerStyle,
  number,
  enabled = true,
  showStatus = true,
}) {
  return (
    <View
      style={[
        styles.container,
        { backgroundColor: enabled ? colors.blue : colors.lighGray },
        containerStyle,
      ]}
    >
      {showStatus && (
        <NtMaterialIcon
          containerStyle={{ marginTop: -2, marginBottom: -2 }}
          name={enabled ? "check-circle" : "information"}
          color={"white"}
        />
      )}
      <NtText style={[styles.text, { marginLeft: 4 }]}>{number}</NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 4,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 25,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontSize: 12,
    fontWeight: "800",
    color: "white",
  },
});

export default NtPinItem;
