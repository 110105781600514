import React, { useRef, useState } from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";
import NtListHeader from "../../components/NtListHeader";
import NtListRow from "../../components/NtListRow";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtText from "../../components/NtText";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import NtModalRingGroupDestinationsNewForm from "./NtModalRingGroupDestinationsNewForm";
import NtModal from "../../components/NtModal";
import ringgroupdestinationscsvcolumndata from "../data/ringgroupdestinationscsvcolumndata";
import { parseError } from "../../api/client";
import NtModalError from "../../components/NtModalError";
import NtListEmpty from "../../components/NtListEmpty";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import Logger from "../../common/utils/Logger";

function NtModalRingGroupDestinationsField({
  title,
  containerStyle,
  destinations,
  onCreate,
  handleCreateRequest,
  onDelete,
  handleDeleteRequest,
  loading = false,
}) {
  const [deleting, setDeleting] = useState(null);
  const [error, setError] = useState(null);

  const newDestinationRef = useRef(null);

  const handleDelete = async (item) => {
    if (onDelete) {
      if (handleDeleteRequest) {
        setError(null);
        setDeleting(item?.destination_number);
        const response = await handleDeleteRequest(item);
        if (response.ok) {
          onDelete(item);
        } else {
          setError(parseError(response));
        }

        setDeleting(null);
      } else {
        onDelete(item);
      }
    }
  };

  const renderAccountPickerModal = () => {
    return (
      <NtModal ref={newDestinationRef}>
        <NtModalRingGroupDestinationsNewForm
          onCancel={() => {
            newDestinationRef.current?.dismiss();
          }}
          handleCreateRequest={handleCreateRequest}
          onCreate={(params) => {
            newDestinationRef.current?.dismiss();
            if (onCreate) {
              onCreate(params);
            }
          }}
        />
      </NtModal>
    );
  };

  const renderCreateNewButton = () => {
    return (
      <NtTouchableEffect
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
          marginBottom: 10,
        }}
        onPress={() => {
          newDestinationRef?.current?.show();
        }}
      >
        <NtMaterialIcon name={"plus-circle"} color={colors.blue} size={20} />
        <NtText style={[styles.buttonText, { marginLeft: 4 }]}>
          Create new destination
        </NtText>
      </NtTouchableEffect>
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <NtText style={[styles.fieldTitle, { marginLeft: 10, marginBottom: 4 }]}>
        {title}
      </NtText>
      <View
        style={{
          paddingLeft: 15,
          paddingRight: 15,
          backgroundColor: colors.lighestGray,
          borderRadius: 25,
        }}
      >
        <NtListHeader
          headerContainerStyle={{ marginRight: 35 }}
          selectedColumns={ringgroupdestinationscsvcolumndata}
        />

        <NtListEmpty
          message="No destinations"
          visible={destinations?.length == 0}
        />

        {destinations?.map((each) => (
          <NtListRow
            key={each[ringgroupdestinationscsvcolumndata[0].id]}
            item={each}
            columns={ringgroupdestinationscsvcolumndata}
            onActionIcon={"delete"}
            onActionColor={"red"}
            onActionLoading={deleting == each?.destination_number}
            onActionPress={() => {
              handleDelete(each);
            }}
          />
        ))}

        <NtModalError error={error} containerStyle={{ marginTop: 8 }} />

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {renderCreateNewButton()}
          {loading && (
            <NtActivityIndicator
              color={colors.blue}
              containerStyle={{ marginLeft: 10 }}
              size="small"
            />
          )}
        </View>
      </View>
      {renderAccountPickerModal()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
  buttonText: {
    fontSize: 12,
    fontWeight: "600",
    color: colors.blue,
    fontStyle: "italic",
  },
});

export default NtModalRingGroupDestinationsField;
