import React, { useState, useContext, useEffect } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import NtTabController from "../../components/NtTabController";
import extensiontypesdata from "../data/extensiontypesdata";
import orderbyoptionsdata from "../../vvn/data/orderbyoptonsdata";
import NtTabItem from "../../components/NtTabItem";

function NtExtensionAdvancedSearchPicker({
  onCancel,
  onFilter,
  onFilterClear,
  filter,
}) {
  const context = useContext(UserContext);

  const [extension, setExtension] = useState(filter?.extension);
  const [callerIdName, setCallerIdName] = useState(filter?.callerIdName);
  const [callerIdNumber, setCallerIdNumber] = useState(filter?.callerIdNumber);
  const [outboundCallerIdName, setOutboundCallerIdName] = useState(
    filter?.outboundCallerIdName
  );
  const [outboundcallerIdNumber, setOutboundCallerIdNumber] = useState(
    filter?.outboundCallerIdNumber
  );
  const [description, setDescription] = useState(filter?.description);
  const [type, setType] = useState(
    extensiontypesdata.find((each) => each.id == filter?.type)
  );
  const [configured, setConfigured] = useState(filter?.configured);
  const [unConfigured, setUnConfigured] = useState(filter?.unConfigured);
  const [temporary, setTemporary] = useState(filter?.temporary);
  const [orderBy, setOrderBy] = useState(
    orderbyoptionsdata.find((each) => each.id == filter?.orderBy)
  );

  const renderTypes = (containerStyle) => {
    return (
      <View style={containerStyle}>
        <NtText style={[styles.fieldTitle, { marginLeft: 4 }]}>Type</NtText>

        <NtTabController
          containerStyle={{ marginTop: 4 }}
          options={extensiontypesdata}
          selection={type}
          onPress={(item) => {
            if (type?.id == item.id) {
              setType(null);
            } else {
              setType(item);
            }

            if (type?.id == "ip") {
              setConfigured(false);
              setUnConfigured(false);
              setTemporary(false);
            }
          }}
        />
      </View>
    );
  };

  const renderSubTypes = (containerStyle) => {
    if (type?.id != "analog") {
      return null;
    }

    return (
      <View style={[{ alignItems: "flex-start" }, containerStyle]}>
        <View
          style={[
            {
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: colors.lighestGray,
              padding: 8,
              borderRadius: 30,
            },
          ]}
        >
          {/* containerStyle, item, onPress, selected = false */}

          <NtTabItem
            textStyle={{ fontSize: 12, fontWeight: "900" }}
            item={{ id: "configured", title: "Configured" }}
            onPress={() => {
              if (!configured) {
                setUnConfigured(false);
              }

              setConfigured(!configured);
            }}
            selected={configured}
          />

          <NtTabItem
            textStyle={{ fontSize: 12, fontWeight: "900" }}
            item={{ id: "unconfigured", title: "Unconfigured" }}
            onPress={() => {
              if (!unConfigured) {
                setConfigured(false);
              }

              setUnConfigured(!unConfigured);
            }}
            selected={unConfigured}
          />

          <NtTabItem
            containerStyle={{ marginRight: 0 }}
            textStyle={{ fontSize: 12, fontWeight: "900" }}
            item={{ id: "temporary", title: "Temporary" }}
            onPress={() => {
              setTemporary(!temporary);
            }}
            selected={temporary}
          />
        </View>
      </View>
    );
  };

  const renderOrderBy = (containerStyle) => {
    return (
      <View style={containerStyle}>
        <NtText style={[styles.fieldTitle, { marginLeft: 4 }]}>Order By</NtText>

        <NtTabController
          containerStyle={{ marginTop: 4 }}
          options={orderbyoptionsdata}
          selection={orderBy}
          onPress={(item) => {
            if (orderBy?.id == item.id) {
              setOrderBy(null);
            } else {
              setOrderBy(item);
            }
          }}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Advanced Search</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Select Specific parameters for search
      </NtText>

      <ScrollView
        scrollEnabled={true}
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <NtModalInputField
          containerStyle={{ marginTop: 15 }}
          title={"Extension"}
          placeholder={"Extension"}
          value={extension}
          setValue={(text) => setExtension(text)}
        />

        <NtModalInputField
          containerStyle={{ marginTop: 15 }}
          title={"Caller Name"}
          placeholder={"Caller Name"}
          value={callerIdName}
          setValue={(text) => setCallerIdName(text)}
        />
        <NtModalInputField
          containerStyle={{ marginTop: 15 }}
          title={"Caller Number"}
          placeholder={"Caller Number"}
          value={callerIdNumber}
          setValue={(text) => setCallerIdNumber(text)}
        />
        <NtModalInputField
          containerStyle={{ marginTop: 15 }}
          title={"Outbound Caller Name"}
          placeholder={"Outbound Caller Name"}
          value={outboundCallerIdName}
          setValue={(text) => setOutboundCallerIdName(text)}
        />
        <NtModalInputField
          containerStyle={{ marginTop: 15 }}
          title={"Outbound Caller Number"}
          placeholder={"Outbound Caller Number"}
          value={outboundcallerIdNumber}
          setValue={(text) => setOutboundCallerIdNumber(text)}
        />

        <NtModalInputField
          containerStyle={{ marginTop: 15 }}
          title={"Description"}
          placeholder={"Description"}
          value={description}
          setValue={(text) => setDescription(text)}
        />

        {renderTypes({ marginTop: 15 })}

        {renderSubTypes({ marginTop: 15 })}

        {renderOrderBy({ marginTop: 15 })}
      </ScrollView>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 25,
        }}
      >
        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <NtModalUnactiveButton
            title={"Clear filters"}
            onPress={() => {
              setExtension("");
              setCallerIdName("");
              setCallerIdNumber("");
              setOutboundCallerIdName("");
              setOutboundCallerIdNumber("");
              setDescription(null);
              setType(null);
              setConfigured(false);
              setUnConfigured(false);
              setTemporary(false);
              setOrderBy(null);
              if (onFilterClear) {
                onFilterClear();
              }
            }}
          />
          <NtModalActiveButton
            containerStyle={{ marginLeft: 15 }}
            title={"Search"}
            enabled={true}
            onPress={() => {
              const filter = {};
              if (extension) {
                filter.extension = extension;
              }
              if (callerIdName) {
                filter.callerIdName = callerIdName;
              }
              if (callerIdNumber) {
                filter.callerIdNumber = callerIdNumber;
              }
              if (outboundCallerIdName) {
                filter.outboundCallerIdName = outboundCallerIdName;
              }
              if (outboundcallerIdNumber) {
                filter.outboundCallerIdNumber = outboundcallerIdNumber;
              }
              if (description) {
                filter.description = description;
              }
              if (type) {
                filter.type = type?.id;
              }
              if (configured) {
                filter.configured = configured;
              }
              if (unConfigured) {
                filter.unConfigured = unConfigured;
              }
              if (temporary) {
                filter.temporary = temporary;
              }
              if (orderBy) {
                filter.orderBy = orderBy?.id;
              }

              if (onFilter) {
                onFilter(filter);
              }
            }}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  cancelButton: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.darkest,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
  addButton: {
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: colors.blue,
  },
  uploadResponseText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.darkest,
  },
  rowText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.lighGray,
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtExtensionAdvancedSearchPicker;
