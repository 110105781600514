import Logger from "../common/utils/Logger";
import client from "./client";

export const fetchInboundRoutes = (domain, page, pageSize, search, filter) => {
  let endpoint = `/v2/pbx/domain/${domain}/dialplan/route/inbound?page=${page}&pagesize=${pageSize}&withExtraInfo=true`;

  //   if (search) {
  //     endpoint += `&search=${search}`;
  //   }

  //   if (filter?.extension) {
  //     endpoint += `&ring_group_extension=${filter?.extension}`;
  //   }
  //   if (filter?.callerIdName) {
  //     endpoint += `&ring_group_caller_id_name=${filter?.callerIdName}`;
  //   }
  //   if (filter?.callerIdNumber) {
  //     endpoint += `&ring_group_caller_id_number=${filter?.callerIdNumber}`;
  //   }
  //   if (filter?.callTimeout) {
  //     endpoint += `&ring_group_call_timeout=${filter?.callTimeout}`;
  //   }
  //   if (filter?.strategy) {
  //     endpoint += `&ring_group_strategy=${filter?.strategy}`;
  //   }

  //   //sort
  //   if (filter?.orderBy) {
  //     endpoint += `&orderby=insert_date&order=${filter?.orderBy}`;
  //   } else {
  //     endpoint += `&orderby=insert_date&order=DESC`;
  //   }

  Logger("Final endpoint for requesting ringgroups: ", endpoint);

  return client.get(endpoint);
};
