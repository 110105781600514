import React, { useState, useContext, useEffect } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import Logger from "../../common/utils/Logger";
import { parseError } from "../../api/client";
import NtTabController from "../../components/NtTabController";
import {
  fetchExtension,
  fetchExtensions,
  updateExtension,
} from "../../api/extension";
import ringgroupstrategytypesdata from "../data/ringgroupstrategytypesdata";
import NtListHeader from "../../components/NtListHeader";
import NtListRow from "../../components/NtListRow";
import { fetchRingGroups, updateRingGroup } from "../../api/ringgroup";
import NtModalAccountPickerField from "./NtModalAccountPickerField";
import NtSearchBar from "../../components/NtSearchBar";
import settings from "../../config/settings";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import NtListRecordsTitle from "../../components/NtListRecordsTitle";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import { parseExtension } from "../utils/ExtensionUtils";
import NtMaterialIcon from "../../components/NtMaterialIcon";

let page = 1;

function NtModalAccountPicker({
  item,
  onCancel,
  onPress,
  defaultOpenTab,
  defaultSelectedItem,
}) {
  const tabs = [
    {
      id: "extension",
      title: "Extension",
      columns: [
        {
          id: "extension",
          title: "Extension",
          default: true,
        },
        {
          id: "effective_caller_id_name",
          title: "Name",
          default: true,
        },
      ],
      request: async (page, search) => {
        const response = await fetchExtensions(
          context.user?.environment?.domain,
          page,
          settings.pageSizeLoad,
          null,
          search,
          {}
        );

        if (response.ok) {
          response.data.data = response.data.extensions;
        }

        return response;
      },
    },
    {
      id: "ringgroup",
      title: "Ring Group",
      columns: [
        {
          id: "ring_group_extension",
          title: "Extension",
          default: true,
        },
        {
          id: "ring_group_caller_id_name",
          title: "Name",
          default: true,
        },
      ],
      request: async (page, search) => {
        const response = await fetchRingGroups(
          context.user?.environment?.domain,
          page,
          settings.pageSizeLoad,
          search,
          {}
        );

        if (response.ok) {
          response.data.data = response.data.ringgroups;
        }

        return response;
      },
    },
    {
      id: "voicemail",
      title: "Voicemail",
      columns: [
        {
          id: "ring_group_extension",
          title: "Extension",
          default: true,
        },
        {
          id: "ring_group_caller_id_name",
          title: "Name",
          default: true,
        },
      ],
      request: async (page, search) => {
        const response = await fetchRingGroups(
          context.user?.environment?.domain,
          page,
          settings.pageSizeLoad,
          search,
          {}
        );

        if (response.ok) {
          response.data.data = response.data.ringgroups;
        }

        return response;
      },
    },
    {
      id: "other",
      title: "Other",
      columns: [
        {
          id: "action",
          title: "Action",
          default: true,
        },
        {
          id: "description",
          title: "Description",
          default: true,
        },
      ],
      request: async (page, search) => {
        const data = [
          {
            action: "Check Voicemail",
            description: "Check Voicemail",
          },
          {
            action: "Company Directory",
            description: "Company Directory",
          },
          {
            action: "Hangup",
            description: "Hangup Call",
          },
        ];

        return {
          ok: true,
          data: { data: data },
        };
      },
    },
  ];

  const [tab, setTab] = useState(tabs[0]);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const context = useContext(UserContext);

  const fetchApp = () => {
    return "transfer";
  };

  const fetchData = () => {
    if (tab?.id == "extension") {
      return `${parseExtension(item[tab?.columns[0]?.id])} XML ${
        context.user?.environment?.domain
      }`;
    } else if (tab?.id == "ringgroup") {
      return `${item[tab?.columns[0]?.id]} XML ${
        context.user?.environment?.domain
      }`;
    } else if (tab?.id == "voicemail") {
      return `*99${item[tab?.columns[0]?.id]} XML ${
        context.user?.environment?.domain
      }`;
    }
  };

  const renderTabs = (containerStyle) => {
    return (
      <View
        style={[{ flexDirection: "row", alignItems: "center" }, containerStyle]}
      >
        <NtTabController
          containerStyle={{ marginTop: 4 }}
          options={tabs}
          selection={tab}
          onPress={(item) => {
            setTab(item);
            setSearch("");

            page = 1;
            handleDataFetch(item, "");
          }}
        />

        {loading && (
          <NtActivityIndicator
            color={colors.almostBlack}
            size="small"
            containerStyle={{ marginLeft: 10 }}
          />
        )}
      </View>
    );
  };

  const handleDataFetch = async (tab, search) => {
    Logger("Fetching Extensions data for tab", tab);

    setLoading(true);

    const response = await tab?.request(page, search);

    Logger("Data response GET: ", response);

    if (response.ok) {
      Logger("Loading data total items", response.data?.data?.length);

      if (page == 1) {
        Logger("Loading for the first time.");
        setData(response.data?.data);
        setTotalItems(response.data?.total);
      } else {
        Logger("Loading more.");
        setData(data.concat(response.data?.data));
      }
    }
    setLoading(false);
  };

  const handleSearch = (text) => {
    setSearch(text);
    page = 1;
    handleDataFetch(tab, text);
  };

  useEffect(() => {
    let tab = tabs[0];
    if (defaultOpenTab) {
      const response = tabs?.find((each) => each.id == defaultOpenTab);
      if (response) {
        tab = response;
      }
    }
    setTab(tab);
    page = 1;
    handleDataFetch(tab, search);
  }, []);

  const renderRow = (item, index) => {
    const isSelected = item[tab?.columns[0].id] == defaultSelectedItem;

    return (
      <NtTouchableEffect
        key={tab?.id + index}
        onPress={() => {
          if (onPress) {
            const data = {
              item: item,
              tab: tab,
              value: item[tab?.columns[0].id],
            };

            onPress(data);
          }
        }}
      >
        <NtListRow
          item={item}
          columns={tab?.columns}
          renderAction={
            <NtMaterialIcon
              name="check"
              color={isSelected ? colors.blue : "white"}
            />
          }
        />
      </NtTouchableEffect>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Select Account</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Select account from{" "}
        <NtText style={{ fontWeight: "900" }}>{tab?.title}</NtText> list
      </NtText>

      {renderTabs({ marginTop: 8 })}

      {tab?.id != "other" && (
        <NtSearchBar
          containerStyle={{ marginTop: 25, marginBottom: 15 }}
          value={search}
          onChangeText={(text) => {
            handleSearch(text);
          }}
        />
      )}

      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
      >
        {tab?.id != "other" && <NtListRecordsTitle count={totalItems} />}
        <NtListHeader
          selectedColumns={tab?.columns}
          headerContainerStyle={{ marginRight: 14 }}
        />

        {data?.map((each, index) => renderRow(each, index))}

        {tab?.id != "other" && (
          <NtLoadMoreButton
            loadingText={"Loading"}
            title="Load More Records"
            loading={loading}
            containerStyle={{ marginTop: 40, marginBottom: 15 }}
            onPress={() => {
              page = page + 1;
              handleDataFetch(tab, search);
            }}
          />
        )}
      </ScrollView>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 25,
        }}
      >
        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 500,
    maxWidth: 600,
    minHeight: 500,
    maxHeight: 800,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtModalAccountPicker;
