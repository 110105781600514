const extensioncsvcolumndata = [
  {
    id: "extension_uuid",
    title: "ID",
  },
  {
    id: "extension",
    title: "Extension",
    default: true,
  },
  {
    id: "effective_caller_id_name",
    title: "Caller Name",
    default: true,
  },
  {
    id: "effective_caller_id_number",
    title: "Caller Number",
  },
  {
    id: "outbound_caller_id_name",
    title: "Outbound Caller Name",
  },
  {
    id: "outbound_caller_id_number",
    title: "Outbound Caller Number",
  },
  {
    id: "emergency_caller_id_name",
    title: "Emergency Caller Name",
  },
  {
    id: "emergency_caller_id_number",
    title: "Emergency Caller Number",
  },
  {
    id: "password",
    title: "Password",
  },
  {
    id: "domain_uuid",
    title: "Domain UUID",
  },
  {
    id: "toll_allow",
    title: "Toll Allow",
    default: true,
  },
  {
    id: "description",
    title: "Description",
    default: true,
  },
];
export default extensioncsvcolumndata;
