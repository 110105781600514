const pinstatisticcolumndata = [
  {
    id: "total_pins_created",
    title: "Total Pins",
    default: true,
  },
  {
    id: "total_pins_active",
    title: "Total Pins Active",
    default: true,
  },
  {
    id: "admin_pins_active",
    title: "Admin Pins",
    default: true,
  },
  {
    id: "prepaid_pins_active",
    title: "Prepaid Pins",
    default: true,
  },
  {
    id: "cabins_pins_active",
    title: "Cabins Pins",
    default: true,
  },
  {
    id: "courtesy_pins_active",
    title: "Courtesy Pins",
    default: true,
  },
];
export default pinstatisticcolumndata;
