import React, { useRef } from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";
import NtModal from "../../components/NtModal";
import NtCallingRatesUpdateForm from "./NtCallingRatesUpdateForm";
import {
  parseDecimalPlaces,
  parseSimpleDisplayDate,
} from "../../common/utils/ControllerUtils";
import NtListRow from "../../components/NtListRow";

function NtCallingRatesItem({ item, columns, onUpdate }) {
  const updateModalRef = useRef();

  const renderUpdateForm = () => {
    return (
      <NtModal ref={updateModalRef}>
        <NtCallingRatesUpdateForm
          item={item}
          onCancel={() => {
            updateModalRef.current?.dismiss();
          }}
          onUpdate={() => {
            updateModalRef.current?.dismiss();
            if (onUpdate) {
              onUpdate();
            }
          }}
        />
      </NtModal>
    );
  };

  return (
    <View style={styles.container}>
      <NtListRow
        item={item}
        columns={columns}
        renderCustom={(item, column) => {
          if (column == "status") {
            // this is for pin.....
            return item[column] == 1 ? "Active" : "Inactive";
          } else if (column == "price" || column == "cost") {
            return `$${parseDecimalPlaces(item[column])}`;
          } else if (column == "creationdate") {
            return parseSimpleDisplayDate(item[column]);
          } else if (column == "creationdate") {
            return parseSimpleDisplayDate(item[column]);
          }
        }}
        onActionPress={() => {
          updateModalRef?.current?.show();
        }}
      />
      {renderUpdateForm()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    fontSize: 12,
    fontWeight: "700",
    color: colors.lighGray,
  },
});

export default NtCallingRatesItem;
