//https://reactnavigation.org/docs/configuring-links/#passing-params
//important links: https://reactnavigation.org/blog/2020/05/16/web-support/

import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { View, StyleSheet } from "react-native";
import NoFoundController from "../common/controllers/NoFoundController";
import routes from "./routes";
import DashboardController from "../dashboard/controllers/DashboardController";
import MarketingController from "../marketing/controllers/MarketingController";
import MarketingHealthController from "../marketing/controllers/MarketingHealthController";
import MarketingCrewAppController from "../marketing/controllers/MarketingCrewAppController";
import MarketingGuestAppController from "../marketing/controllers/MarketingGuestAppController";
import MarketingPBXSystemController from "../marketing/controllers/MarketingPBXSystemController";
import MessageV2Controller from "../maritime/controllers/MessageV2Controller";
import ExtensionController from "../extensions/controllers/ExtensionController";
import GatewayReportController from "../extensions/controllers/GatewayReportController";
import AppAccessController from "../extensions/controllers/AppAccessController";
import FeatureCodeController from "../common/controllers/FeatureCodeController";
import MarketingHospitalityPBXController from "../marketing/controllers/MarketingHospitalityPBXController";
import DocumentController from "../docs/controllers/DocumentController";
import PinManagementController from "../vvn/controllers/PinManagementController";
import PinHistoryController from "../vvn/controllers/PinHistoryController";
import ReportScheduleController from "../vvn/controllers/ReportScheduleController";
import CallingRateController from "../vvn/controllers/CallingRateController";
import UpgradeController from "../common/controllers/UpgradeController";
import GatewayController from "../extensions/controllers/GatewayController";
import RingGroupController from "../extensions/controllers/RingGroupController";
import InboundRouteController from "../extensions/controllers/InboundRouteController";
import GovController from "../common/controllers/GovController";

const Stack = createNativeStackNavigator();

function AppNavigator({ onControllerChanged, initialRoute }) {
  return (
    <Stack.Navigator
      initialRouteName={initialRoute}
      screenListeners={{
        state: (e) => {
          const controller =
            e.data.state.routes[e.data.state.routes.length - 1].name;
          if (onControllerChanged) {
            onControllerChanged(controller);
          }
        },
      }}
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name={routes.dashboard}
        component={DashboardController}
        options={{ title: "MARITIME | Dashboard" }}
      />

      <Stack.Screen
        name={routes.messagev2}
        component={MessageV2Controller}
        options={{ title: "MARITIME | Message" }}
      />

      <Stack.Screen
        name={routes.pinmanagement}
        component={PinManagementController}
        options={{ title: "MARITIME | Pin Management" }}
      />

      <Stack.Screen
        name={routes.pinhistory}
        component={PinHistoryController}
        options={{ title: "MARITIME | Pin History" }}
      />

      <Stack.Screen
        name={routes.callingrate}
        component={CallingRateController}
        options={{ title: "MARITIME | Calling Rates" }}
      />

      <Stack.Screen
        name={routes.reportschedule}
        component={ReportScheduleController}
        options={{ title: "MARITIME | Report Schedule" }}
      />

      <Stack.Screen
        name={routes.extension}
        component={ExtensionController}
        options={{ title: "MARITIME | Extensions" }}
      />

      <Stack.Screen
        name={routes.inboundroute}
        component={InboundRouteController}
        options={{ title: "MARITIME | Inbound Route" }}
      />

      <Stack.Screen
        name={routes.gatewayreport}
        component={GatewayReportController}
        options={{ title: "MARITIME | Gateway Report" }}
      />

      <Stack.Screen
        name={routes.gateway}
        component={GatewayController}
        options={{ title: "MARITIME | Gateway" }}
      />

      <Stack.Screen
        name={routes.appaccess}
        component={AppAccessController}
        options={{ title: "MARITIME | App Access" }}
      />

      <Stack.Screen
        name={routes.ringgroup}
        component={RingGroupController}
        options={{ title: "MARITIME | Ring Groups" }}
      />

      <Stack.Screen
        name={routes.docs}
        component={DocumentController}
        options={{ title: "MARITIME | Documentation" }}
      />

      <Stack.Screen
        name={routes.featurecode}
        component={FeatureCodeController}
        options={{ title: "MARITIME | Feature Code" }}
      />

      <Stack.Screen
        name={routes.marketing}
        component={MarketingController}
        options={{ title: "MARITIME | Marketing" }}
      />

      <Stack.Screen
        name={routes.health}
        component={MarketingHealthController}
        options={{ title: "MARITIME | Health" }}
      />

      <Stack.Screen
        name={routes.crewapp}
        component={MarketingCrewAppController}
        options={{ title: "MARITIME | Crew App" }}
      />

      <Stack.Screen
        name={routes.guestapp}
        component={MarketingGuestAppController}
        options={{ title: "MARITIME | Guest App" }}
      />

      <Stack.Screen
        name={routes.pbxsystem}
        component={MarketingPBXSystemController}
        options={{ title: "MARITIME | PBX System" }}
      />

      <Stack.Screen
        name={routes.hospitalitypbx}
        component={MarketingHospitalityPBXController}
        options={{ title: "MARITIME | Hospitality PBX" }}
      />

      <Stack.Screen
        name={routes.starlink}
        component={MarketingController}
        options={{ title: "MARITIME | Starlink" }}
      />

      <Stack.Screen
        name={routes.gov}
        component={GovController}
        options={{ title: "MARITIME | Gov" }}
      />

      <Stack.Screen
        name={routes.upgrade}
        component={UpgradeController}
        options={{ title: "MARITIME | Upgrade" }}
      />

      <Stack.Screen
        name={routes.notfound}
        component={NoFoundController}
        options={{ title: "MARITIME | No Found" }}
      />
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default AppNavigator;
