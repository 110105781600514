import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import NtTabItem from "./NtTabItem";

function NtTabController({ containerStyle, options, selection, onPress }) {
  const renderRow = (item) => {
    return (
      <NtTabItem
        key={item.id}
        item={item}
        onPress={() => {
          if (onPress) {
            onPress(item);
          }
        }}
        selected={selection?.id == item?.id}
      />
    );
  };
  return (
    <View style={[styles.container, containerStyle]}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        showsHorizontalScrollIndicator={false}
        horizontal={true}
      >
        {options.map((each) => renderRow(each))}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
});

export default NtTabController;
