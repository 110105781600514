const orderbyoptionsdata = [
  {
    id: "DESC",
    title: "Newest on Top",
  },
  {
    id: "ASC",
    title: "Oldest on Top",
  },
];
export default orderbyoptionsdata;
