import React, { act } from "react";
import { View, StyleSheet } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import colors from "../../config/colors";
import BaseController from "../../common/base/BaseController";
import { fetchStatistics } from "../../api/dashboard";
import Logger from "../../common/utils/Logger";
import NtSection from "../components/NtSection";
import pbxstatisticcolumndata from "../data/pbxstatisticcolumndata";
import NtDashboardHeader from "../components/NtDashboardHeader";
import NtDashboardRow from "../components/NtDashboardRow";
import pinstatisticcolumndata from "../data/pinstatisticcolumndata";
import cdrstatisticcolumndata from "../data/cdrstatisticcolumndata";
import NtText from "../../components/NtText";

class DashboardController extends BaseController {
  state = {
    statistics: null,
    loading: false,
  };

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.handleDataFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
  };

  handleDataFetch = async () => {
    this.setState({ loading: true });
    const response = await fetchStatistics(
      this.context.user?.environment?.domain
    );

    Logger("Fetched statistics data", response);

    if (response.ok) {
      this.setState({ statistics: response.data });
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Dashboard"}
        showFooter={true}
        maxWidth={"100%"}
      >
        <NtSection title={"PBX"} containerStyle={{ marginTop: 35 }}>
          <View>
            <NtDashboardHeader
              visible={true}
              columns={pbxstatisticcolumndata}
            />
            <NtDashboardRow
              item={this.state.statistics?.pbx}
              columns={pbxstatisticcolumndata}
            />
          </View>
        </NtSection>

        <NtSection
          title={"Calling Cards - Pins"}
          containerStyle={{ marginTop: 35 }}
        >
          <View>
            <NtDashboardHeader
              visible={true}
              columns={pinstatisticcolumndata}
            />
            <NtDashboardRow
              item={this.state.statistics?.callingcards?.pins}
              columns={pinstatisticcolumndata}
            />
          </View>
        </NtSection>

        <NtSection
          title={"Calling Cards - CDRs"}
          containerStyle={{ marginTop: 35 }}
        >
          {/* Cabin */}
          <View>
            <NtDashboardHeader
              visible={true}
              columns={cdrstatisticcolumndata}
            />
            <NtDashboardRow
              item={{
                ...this.state.statistics?.callingcards?.cdrs?.cabin,
                type: "Cabin",
              }}
              columns={cdrstatisticcolumndata}
            />
            <NtDashboardRow
              item={{
                ...this.state.statistics?.callingcards?.cdrs?.prepaid,
                type: "Prepaid",
              }}
              columns={cdrstatisticcolumndata}
            />

            <NtDashboardRow
              item={{
                ...this.state.statistics?.callingcards?.cdrs?.admin,
                type: "Admin",
              }}
              columns={cdrstatisticcolumndata}
            />

            <NtDashboardRow
              item={{
                ...this.state.statistics?.callingcards?.cdrs?.courtesy,
                type: "Courtesy",
              }}
              columns={cdrstatisticcolumndata}
            />

            <NtDashboardRow
              item={{
                ...this.state.statistics?.callingcards?.cdrs?.general,
                type: "Total",
              }}
              textStyle={{ fontWeight: "900", color: colors.almostBlack }}
              columns={cdrstatisticcolumndata}
            />
          </View>
        </NtSection>

        <NtSection title={"Messaging"} containerStyle={{ marginTop: 35 }}>
          <View>
            <NtText style={[styles.messages, { marginLeft: 4, marginTop: 8 }]}>
              Messages: {this.state.statistics?.messaging?.messages}
            </NtText>
          </View>
        </NtSection>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 14,
    fontWeight: "800",
    color: colors.lighGray,
  },
  messages: {
    fontSize: 14,
    fontWeight: "800",
    color: colors.lighGray,
  },
});

export default DashboardController;
