import React, { useState, useContext, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import Logger from "../../common/utils/Logger";
import { parseError } from "../../api/client";
import NtTabController from "../../components/NtTabController";
import { updateExtension } from "../../api/extension";
import ringgroupstrategytypesdata from "../data/ringgroupstrategytypesdata";
import NtListHeader from "../../components/NtListHeader";
import NtListRow from "../../components/NtListRow";
import {
  createRingGroupDestination,
  deleteRingGroup,
  deleteRingGroupDestination,
  fetchRingGroupDestinations,
  updateRingGroup,
} from "../../api/ringgroup";
import NtModalAccountPickerField from "./NtModalAccountPickerField";
import NtModalError from "../../components/NtModalError";
import NtModalRingGroupDestinationsField from "./NtModalRingGroupDestinationsField";

function NtInboundRouteUpdateForm({ item, onCancel, onUpdate, onDelete }) {
  const destinationcsvcolumns = [
    {
      id: "destination",
      title: "Destination",
      default: true,
    },
    {
      id: "delay",
      title: "Delay",
      default: true,
    },
    {
      id: "timeout",
      title: "Timeout",
      default: true,
    },
  ];

  const [callerIdName, setCallerIdName] = useState(
    item.ring_group_caller_id_name
  );
  const [callerIdNumber, setCallerIdNumber] = useState(
    item.ring_group_caller_id_number
  );
  const [callTimeout, setCalltimeout] = useState(item.ring_group_call_timeout);
  const [destinations, setDestinations] = useState([]);
  const [timeoutDestination, setTimeoutDestination] = useState(
    item?.ring_group_timeout_action
  );
  const [strategy, setStrategy] = useState(
    ringgroupstrategytypesdata.find(
      (each) => each.id == item?.ring_group_strategy
    )
  );
  const [loadingDestinations, setLoadingDestinations] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const context = useContext(UserContext);

  const isRequiredFields = () => {
    return callerIdName && callerIdNumber && callTimeout && strategy;
  };

  const handleDestinationsFetch = async () => {
    setLoadingDestinations(true);
    const response = await fetchRingGroupDestinations(
      context.user?.environment?.domain,
      item?.ring_group_extension,
      1,
      10000
    );

    if (response.ok) {
      Logger("Destinations data", response);
      setDestinations(response.data.ringgroupdestinations);
    }

    setLoadingDestinations(false);
  };

  const handleUpdate = async () => {
    setLoading(true);

    setError(null);

    const params = {
      ring_group_call_timeout: callTimeout,
      ring_group_strategy: strategy?.id,
      ring_group_caller_id_name: callerIdName,
      ring_group_caller_id_number: callerIdNumber,
      ring_group_timeout_action: timeoutDestination,
    };

    const response = await updateRingGroup(
      context.user?.environment?.domain,
      item?.ring_group_extension,
      params
    );

    Logger("Ringgroup Update Patch", response);

    if (response.ok) {
      if (onUpdate) {
        onUpdate();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    setError(null);

    const response = await deleteRingGroup(
      context.user?.environment?.domain,
      item?.ring_group_extension
    );

    if (response.ok) {
      if (onDelete) {
        onDelete();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  const renderStrategy = (containerStyle) => {
    return (
      <View style={containerStyle}>
        <NtText style={[styles.fieldTitle, { marginLeft: 4 }]}>
          Strategy *
        </NtText>

        <NtTabController
          containerStyle={{ marginTop: 4 }}
          options={ringgroupstrategytypesdata}
          selection={strategy}
          onPress={(item) => {
            setStrategy(item);
          }}
        />
      </View>
    );
  };

  useEffect(() => {
    handleDestinationsFetch();
  }, []);

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Update Inbound Route</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Update Inbound Route{" "}
        <NtText style={{ fontWeight: "900" }}>
          {item.ring_group_extension}
        </NtText>
      </NtText>

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Caller Id Name *"}
        placeholder={"Caller Id Name"}
        value={callerIdName}
        setValue={(text) => setCallerIdName(text)}
      />
      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Caller Id Number *"}
        placeholder={"Caller Id Number"}
        value={callerIdNumber}
        setValue={(text) => setCallerIdNumber(text)}
      />
      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Call Timeout *"}
        placeholder={"Call Timeout"}
        value={callTimeout}
        setValue={(text) => setCalltimeout(text)}
      />

      <NtModalRingGroupDestinationsField
        title={"Destinations"}
        containerStyle={{ marginTop: 15 }}
        destinations={destinations}
        loading={loadingDestinations}
        onCreate={(item) => {
          Logger("On Create", item);
        }}
        onDelete={(item) => {
          Logger("On Delete", item);
        }}
        handleCreateRequest={async (params) => {
          Logger("Will handle the create ", params);

          const response = await createRingGroupDestination(
            context.user?.environment?.domain,
            item?.ring_group_extension,
            params
          );

          if (response.ok) {
            handleDestinationsFetch();
          }

          return response;
        }}
        handleDeleteRequest={async (params) => {
          Logger("Will handle the delete ", params);

          const response = await deleteRingGroupDestination(
            context.user?.environment?.domain,
            item?.ring_group_extension,
            params?.destination_number
          );

          if (response.ok) {
            handleDestinationsFetch();
          }

          return response;
        }}
      />

      <NtModalAccountPickerField
        containerStyle={{ marginTop: 15 }}
        title={"Timeout Destination"}
        placeholder={"Timeout Destination"}
        value={timeoutDestination}
        setValue={(text) => setTimeoutDestination(text)}
      />

      {renderStrategy({ marginTop: 15 })}

      <NtModalError error={error} containerStyle={{ marginTop: 6 }} />

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 40,
        }}
      >
        <NtModalActiveButton
          containerStyle={{ backgroundColor: "red" }}
          title={"Delete"}
          icon={"delete"}
          onPress={() => {
            handleDelete();
          }}
          enabled={true}
        />

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {loading && (
            <NtActivityIndicator
              color={colors.blue}
              size="small"
              containerStyle={{ marginRight: 25 }}
            />
          )}

          <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />

          <NtModalActiveButton
            containerStyle={{ marginLeft: 15 }}
            title={"Update"}
            onPress={() => {
              if (isRequiredFields()) {
                handleUpdate();
              }
            }}
            enabled={isRequiredFields()}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtInboundRouteUpdateForm;
