import React from "react";
import { View, StyleSheet } from "react-native";
import NtTouchableEffect from "./NtTouchableEffect";
import NtMaterialIcon from "./NtMaterialIcon";
import colors from "../config/colors";
import NtText from "./NtText";

function NtPageActionButton({ containerStyle, icon, onPress, title }) {
  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={onPress}
    >
      <NtMaterialIcon name={icon} color="white" size={18} />
      {title && <NtText style={styles.title}>{title}</NtText>}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 25,
    height: 25,
    borderRadius: 18,
    backgroundColor: colors.blue,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  title: {
    color: "white",
    fontSize: 12,
    fontWeight: "600",
  },
});

export default NtPageActionButton;
