import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";

function NtDescriptionHightlight({ description, onPress }) {
  const isGateway = () => {
    return description?.match(/\d+,\d+,\d+,\d+/);
  };

  if (isGateway()) {
    return (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <NtTouchableEffect onPress={onPress}>
          <NtText style={styles.highlight}>
            Gateway = {description?.split(",")[0]}
          </NtText>
        </NtTouchableEffect>

        <NtText style={[styles.highlight, { marginLeft: 6 }]}>
          Port = {description?.split(",")[1]}
        </NtText>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <NtText style={[styles.text]}>{description}</NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  text: {
    fontSize: 12,
    fontWeight: "700",
    color: colors.lighGray,
  },
  highlight: {
    backgroundColor: colors.lighestGray,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: 12,
    borderRadius: 20,
    color: colors.lighGray,
    fontWeight: "700",
  },
});

export default NtDescriptionHightlight;
